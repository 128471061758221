import { CodeName } from "@/assets/commontype/CodeName";
import { ItemTag, itemTags } from "@/assets/commontype/ItemTag";
import * as calcUtil from "@/util/calcUtil";
import { PageInfo } from "./mainPageTmp";
import moment from 'moment';

//最大過去表示可能週数（日別）
export const maxWeeksPastDaily = 52;
//最大過去表示可能週数（週別）
export const maxWeeksPastWeekly = 104;
//最大未来表示可能週数
export const maxWeeksFuture = 8;

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "no", 
  "attribute1Value", 
  "rowHeader",
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  // "attribute1Label",
  // "attribute2Label",
  "attribute2Value",
  "itemTag",
];
//デフォルト表示列
//Excelデータシート用
export const defaultVisibleColumnsKey_ExcelDataSheet:string[] = [
  "no", 
  'centerCD',
  'centerNM',
  'itemCD',
  'itemNM',
  //以下に貼り付け
  // 'area',
  // 'pref',
  // 'janCD',
  // 'makerCD',
  'makerNM',
  // 'categoryCD',
  // 'categoryNM',
  'blIrisu',
  'csIrisu',
  // 'lot',
  'capacity',
  // 'bestBefore',
  'teika',
  // 'tatene',
  //'tanaban',
  // "tokuisakiCD",
  // "tokuisakiNM",
  // "tokuisakiItemCD",
  // "oyaCenter",
  // "koCenter",

];
//強制表示行
export const mustVisibleRowsKey:string[] = [
  // "warehousing",  //: "入庫",
  // "shipping",       //: "出庫",
  // "stockNormal",     //: "在庫（良品）",
];
//デフォルト表示行
export const defaultVisibleRowsKey:string[] = [
  // "storeSales",    //: "店頭売上",
  // "transfer",     //: "転送予定",
  "warehousing",  //: "入庫",
  // "bestBefore",    //: "賞味期限",
  // "storeOrder",   //: "受注",
  // "shipping_original",
  // "shipping",       //: "出庫",
  "shipping_storeOrder",       //: "出庫",
  "stockNormal",     //: "在庫（良品）",
  // "stockUseUp",     //: "在庫（出切）",
  // "stockReturned",  //: "在庫（返品）",

  // "similarItem_warehousing",
  // "similarItem_shipping",
  // "similarItem_stockNormal",

];

//デフォルト表示項目(9行必要)
export const defaultVisibleAttributes:string[][] = [
  [
    'center', // 倉庫
    'itemCD', // 商品CD
    'itemNM', // 商品名
    'janCD', // janCD
    '',
    '',
    '',
    '',
    '',
  ],
  [
    'blIrisu', // BL入数
    'csIrisu', // CS入数
    'lot',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
];

//YMD
export type YMD = {
  ymdID?: number,
  name?: string,
  weekday?: string,
  type?: 'today' | 'past' | 'past_hidden' | 'future' ,
  foldable?: boolean,  //折りたたみ可能
  fold?: boolean,  //折りたたみ
  week_id?: number,  //週識別子
  week?: boolean,  //週
  week_ymdfrom?: number,   //週開始日
  week_ymdto?: number,  //週終了日
  index?: number,
  yesterdayIndex?: number, //先週
  lastWeekIndex?: number, //先週
  diffIndex?: number,
  holidayDiv?: 'saturday' | 'sunday' | 'holiday',
}
//倉庫・商品
export type CenterItem = {
  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerNM?: string | null, //倉庫
  centerSortInput?: number | null, //倉庫並び順
  centerSortOutput?: number | null, //倉庫並び順
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県

  itemCD?: string | null, //商品CD
  itemCD5?: string | null, //商品CD(5桁コード)
  itemNM?: string | null, //商品名
  janCD?: string | null,   //JAN
  makerCD?: string | null, //メーカー
  makerNM?: string | null, //メーカー
  shiireCD?: string | null, //仕入先
  shiireNM?: string | null, //仕入先
  shiireTEL?: string | null, //仕入先
  shiireFAX?: string | null, //仕入先
  categoryCD?: string | null, //ｶﾃｺﾞﾘ
  categoryNM?: string | null, //ｶﾃｺﾞﾘ
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  lot?: number | null,  //発注単位
  csIrisu_lot?: number | null,  //CS入数×発注単位
  blIrisu_csIrisu?: string | null,  //BL入数×CS入数
  capacity?: string | null, //内容量
  bestBefore?: number | null, //賞味期間
  allowablePeriod?: number | null, //許容期間
  freshnessDaysOfAlert?: number | null, //通知日数
  shubaiLimitAlert?: number | null, //終売日N日前以降にアラート
  todayUseFlg?: boolean,
  weekdayWarnFlg?: boolean,
  shippingJuchuOrInput?: boolean,
  teika?: number | null, //定価
  tatene?: number | null, //建値
  capacityCS?: number | null, //容量（積載）㎥

  similarItemCD?: string, //類似商品コード
  similarItemBlIrisu?: number | null,  //容量1(BL入数) 

  shubai_date?: string | null, //終売日

  makerCenterName?: string,  //メーカー倉庫名
  tanaban?: string | null, //棚番
  zaikoKigenUketsukeYmd?: number | null, //在庫期限受付日
  zaikoKigenOfAlert?: string | null, //許容期限アラート在庫期限(YY/MM/DD)
  zaikoKigen?: string | null, //在庫期限(YY/MM/DD)
  zaikoKigenBL?: string | null, //在庫期限(YY/MM/DD)

  tokuisakiCD?: string | null, //得意先
  tokuisakiNM?: string | null, //得意名
  tokuisakiItemCD?: string | null, //得意先

  teibanTokuisakiCD?: string | null, //得意先
  teibanTokuisakiNM?: string | null, //得意名
  teibanTokuisakiItemCD?: string | null, //得意先

  oyaCenter1?: {code:string, name:string, fromYMD:number, toYMD:number} | null, //親センター
  oyaCenter2?: {code:string, name:string, fromYMD:number, toYMD:number} | null, //親センター
  oyaCenter3?: {code:string, name:string, fromYMD:number, toYMD:number} | null, //親センター
  koCenter1?: {code:string, name:string, fromYMD:number, toYMD:number} | null, //子センター
  koCenter2?: {code:string, name:string, fromYMD:number, toYMD:number} | null, //子センター
  koCenter3?: {code:string, name:string, fromYMD:number, toYMD:number} | null, //子センター

  bundleGroup?: string,  //バンドルグループ
  bundleItemCD?: string,  //バンドル商品(出荷する側)
  bundledItemCD?: string,  //被バンドル商品（発注する側）
  bundleRate?: number | null, //バンドル比率
  
  notTarget?: boolean,  //検索非対象フラグ（子センター分）
}
//倉庫・商品 (納品不可日)
export type DisableDateData = {
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫
	disableDates?: any, //{"YYYY-MM-DD":""}
}
//倉庫・商品
export type ItemCommonData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする
    
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	loadedQty?: number,

  delFlg?: boolean,
}
//倉庫・商品・タグ
export type ItemTagData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする
    
  itemCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	tagCD?: 'NEW'|'CPN'|'CUT'|'EMC'|'PKG'|'OTH'|string, //タグ属性　'NEW':'新規', 'CPN':'キャンペーン', 'CUT':'カット', 'EMC':'緊急カット', 'PKG':'パッケージ変更', 'OTH':'その他'
  itemTag?: ItemTag,

	comment?: string, //コメント
  termFlg?: boolean,
  termStartDate?: number,
  termEndDate?: number,
  validPast?: boolean, //過去有効なタグ
  validToday?: boolean, //今日有効なタグ
  validFuture?: boolean, //未来有効なタグ

  similarItemCD?: string, //類似商品コード
  similarItemNM?: string | null, //類似商品名
  similarItemBlIrisu?: number | null,  //容量1(BL入数) 

  delFlg?: boolean,
}
//倉庫・メーカー・タグ
export type MakerTagData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする
    
  makerCD?: string | null, //商品CD
  centerCD?: string | null, //倉庫

	blInputFlg?: boolean,	//BL入力可不可

	leadTimeFlg?: boolean,	//リードタイム有無
	leadTimeDays?: number,	//リードタイム日数
  leadTimeExcludeSaturdayFlg?: boolean,	//リードタイム(土曜を除く)
	leadTimeContainHolidayFlg?: boolean,	//リードタイム(祝日を含む)

	specifiedDateFlg?: boolean,	//指定日有無
	specifiedDateSunFlg?: boolean,	//指定日：曜日：日
	specifiedDateMonFlg?: boolean,	//指定日：曜日：月
	specifiedDateTueFlg?: boolean,	//指定日：曜日：火
	specifiedDateWedFlg?: boolean,	//指定日：曜日：水
	specifiedDateThrFlg?: boolean,	//指定日：曜日：木
	specifiedDateFriFlg?: boolean,	//指定日：曜日：金
	specifiedDateSatFlg?: boolean,	//指定日：曜日：土
	specifiedDateHolFlg?: boolean,	//指定日：曜日：祝日

  closeTime?: string,

  fractionRounding?: '0'|'1'|'2'|'3', //端数処理 (0:無し 1:切捨て 2:四捨五入 3:切り上げ)

  delFlg?: boolean,
}
//メーカー倉庫残在庫
export type MakerLimitData = {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする
  makerCD?: string | null, //商品CD
  itemCD?: string | null, //商品CD
  bumonCD?: string | null, //
  makerCenterName?: string | null,  //メーカー倉庫名
  stockLimitDate?: string,  //メーカー倉庫　メーカー残在庫日付
	stockLimitBL?: number, //メーカー倉庫　メーカー残在庫BL数
  warehousingBL_past?: number, //入荷済み数
	overFlg_past?: boolean, //メーカー残超過済みフラグ
  warehousingBL_calc?: number, //入荷済み数
	overFlg_calc?: boolean, //メーカー残超過済みフラグ
  zanBL_calc?: number, //残
}

export type AlertSettings = {
  stockMinusAfterNdays?: number,  //N日後在庫マイナス
  stockMdaysOrMore?: number,  //M日間以上の在庫量
  storeOrderNover?: number,    //当日受注が、前週同曜日の受注数よりXBL超過　★
  storeOrderNtimes?: number,    //当日受注が、前週同曜日の受注数よりN倍超過　★
};

export const getNumPS = (numBL:number, blIrisu:number):number => {
  return calcUtil.multiply(numBL, blIrisu);
}
export const getNumBLfromPS = (numPS:number, blIrisu:number):number => {
  return calcUtil.divide(numPS, blIrisu);
}
export const getNumBLfromCS = (numCS:number, csIrisu:number):number => {
  return calcUtil.multiply(numCS, csIrisu);
}
export const getNumCS = (numBL:number, csIrisu:number):number => {
  return calcUtil.divide(numBL, csIrisu);
}
export const getNum = (numBL:number, numUnit:'CS'|'BL'|'PS'|string, blIrisu:number, csIrisu:number):number => {
  switch (numUnit) {
    case 'CS':
      return getNumCS(numBL, csIrisu);
    case 'BL':
      return numBL;
    case 'PS':
      return getNumPS(numBL, blIrisu);
    default:
      return null;
  }
}

export interface InfoDataStoreSales {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  storeSalesCS_total?: number | null, // 出庫	
  storeSalesBL_total?: number | null, // 出庫	
  storeSalesPS_total?: number | null, // 出庫	
  
  storeSalesBL?: number | null, // 店頭売上	

  state?: ''|'result'|'calc',  //状態：実績、予測値、入力値
  // editor?: string, //更新者
}
export interface InfoDataTransfer {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  transferCS_total?: number | null, // 出庫	
  transferBL_total?: number | null, // 出庫	
  transferPS_total?: number | null, // 出庫	
  transferBL_result?: number | null, // 転送予定	
  transferBL_result1?: number | null, // 転送予定（一般）
  transferBL_result2?: number | null, // 転送予定（特売）
  transferBL_result3?: number | null, // 転送予定（予約）
  transferBL_edit?: number | null, // 転送予定	

  koShippingBL?: number | null, // 子出荷
  
  state?: ''|'result'|'calc'|'edit_own'|'edit_other'|'edit_mix',  //状態：実績、予測値、入力値
  // editor?: string, //更新者
}
export interface InfoDataWarehousing {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  warehousingCS_total?: number | null, // 出庫	
  warehousingBL_total?: number | null, // 出庫	
  warehousingPS_total?: number | null, // 出庫	

  warehousingBL_calc?: number | null, // 出庫	
  warehousingBL_edit?: number | null, // 出庫	

  warehousingBL_if?: number | null, // 連携中

  // warehousingBL_result?: number | null, // 出庫	
  warehousingPS_result?: number | null, // 出庫	
  warehousingPS_result1?: number | null, // 出庫（一般）
  warehousingPS_result2?: number | null, // 出庫（特売）
  warehousingPS_result3?: number | null, // 出庫（予約）

  warehousingBL_plan?: number | null, //予定
  
  capacityCS?: {}, //メーカー別の容量（積載）㎥

  bundleWarehousingBL?: number | null, // バンドル入庫

  state?: ''|'result'|'calc'|'edit_own'|'edit_other'|'edit_mix'|'edit_auto'|'plan',  //状態：実績、予測値、入力値
}
export interface InfoDataOrder {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  state?: ''|'result',  //状態：実績、予測値、入力値
  // editor?: string, //更新者
  orders?: {
    orderNo:string,
    orderBL: number | null,
    tekiyou:string,
    syoriKbn: '1'|'2'|'3'|'8'|'9'|string, //''1'=一般、'2'=特売、'3'=予約、'8'=返品、'9'=出切
  }[],
}

export interface InfoDataBestBefore {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  bestBefore?: string | null, // 賞味期限	
  bestBeforePS?: number | null, // 賞味期限	BL数

  state?: ''|'result',  //状態：実績、予測値、入力値
  // editor?: string, //更新者
}
export interface InfoDataStoreOrder {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  // tokuisakis?: { name: string, ps: number }[], //得意先名称とPS

  storeOrderCS_total?: number | null, // 出庫	
  storeOrderBL_total?: number | null, // 出庫	
  storeOrderPS_total?: number | null, // 出庫	

  // storeOrderBL?: number | null, // 受注	
  storeOrderPS?: number | null, // 受注	

  state?: ''|'result'|'calc',  //状態：実績、予測値、入力値
  // editor?: string, //更新者

  groups?: {   //企業G内訳（定番検索時のみ使用する）
    group2CD: string | null, //企業G2CD
    group1CD: string | null, //企業G1CD
    group1Name: string | null, //企業G1CD
    storeOrderPS: number | null, // 受注	
  }[],
}
export interface InfoDataShipping {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  shippingCS_total?: number | null, // 出庫	
  shippingBL_total?: number | null, // 出庫	
  shippingPS_total?: number | null, // 出庫	

  shippingBL_calc?: number | null, // 出庫	
  shippingBL_edit?: number | null, // 出庫	

  // shippingBL_result?: number | null, // 出庫	
  shippingPS_result?: number | null, // 出庫	
  shippingPS_result1?: number | null, // 出庫（一般）

  shippingBL_zantei?: number | null, // 出庫	(暫定発注数)

  shippingPS_original?: number | null, // 出庫(移管含まず）

  state?: ''|'zantei'|'result'|'calc'|'edit_own'|'edit_other'|'edit_mix',  //状態：暫定発注、実績、予測値、入力値
  // editor?: string, //更新者

  groups?: {   //企業G内訳（定番検索時のみ使用する）
    group2CD: string | null, //企業G2CD
    group1CD: string | null, //企業G1CD
    group1Name: string | null, //企業G1CD
    shippingPS_result: number | null, // 出庫	
  }[],
}
export interface InfoDataShippingGroup1 {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品
  group2CD?: string | null, //企業G2CD
  group1CD?: string | null, //企業G1CD
  group1NM?: string | null, //企業G2名

  shippingPS_result?: number | null, // 出庫	

  state?: ''|'result',  //状態：暫定発注、実績、予測値、入力値
  ikanFlg?: boolean | null, //移管分
}
export interface InfoDataShippingTokubai {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID_f?: number | null, //年月日
  ymdID_t?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  group2CD: string | null, //企業G2CD（定番検索時のみ使用する）
  group1CD: string | null, //企業G1CD（定番検索時のみ使用する）

  shippingPS_result?: number | null, // 出庫	

  state?: ''|'result',  //状態：暫定発注、実績、予測値、入力値
  // editor?: string, //更新者
}
export interface InfoDataStock {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品
  todayType?: 'today' | 'now' | string,   //当日在庫表示内容

  stockNormalCS_total?: number | null, // 出庫	
  stockNormalBL_total?: number | null, // 出庫	
  stockNormalPS_total?: number | null, // 出庫	

  stockUseUpCS_total?: number | null, // 出庫	
  stockUseUpBL_total?: number | null, // 出庫	
  stockUseUpPS_total?: number | null, // 出庫	

  stockReturnedCS_total?: number | null, // 出庫	
  stockReturnedBL_total?: number | null, // 出庫	
  stockReturnedPS_total?: number | null, // 出庫	

  stockNormalBL?: number | null, // 在庫（良品）	
  stockNormal1BL?: number | null, // 在庫（一般	
  stockNormal2BL?: number | null, // 在庫（特売）	
  stockNormal3BL?: number | null, // 在庫（予約）	
  stockUseUpBL?: number | null, // 在庫（出切）	
  stockReturnedBL?: number | null, // 在庫（返品）	

  stateNormal?: ''|'result'|'calc'|'edit_own'|'edit_other'|'edit_mix',  //状態：実績、予測値、入力値
  stateUseUp?: ''|'result'|'calc'|'edit_own'|'edit_other'|'edit_mix',  //状態：実績、予測値、入力値
  stateReturned?: ''|'result'|'calc'|'edit_own'|'edit_other'|'edit_mix',  //状態：実績、予測値、入力値
  // editor?: string, //更新者
}
export const getShippingBL_original = (data:InfoDataShipping, blIrisu:number):number => {
  return calcUtil.divide(data?.shippingPS_original, blIrisu);
}
export const getShippingBL = (data:InfoDataShipping, blIrisu:number):number => {
  switch (data?.state) {
    case "zantei":
      return data?.shippingBL_zantei;
    case "result":
    case "edit_own":
    case "edit_other":
    case "edit_mix":
      return calcUtil.plus(calcUtil.divide(data?.shippingPS_result, blIrisu), data?.shippingBL_edit);
    case "calc":
    default:
      return data?.shippingBL_calc;
  }
}
export const getShippingBL1 = (data:InfoDataShipping, blIrisu:number):number => {
  switch (data?.state) {
    case "zantei":
      return data?.shippingBL_zantei;
    case "result":
    case "edit_own":
    case "edit_other":
    case "edit_mix":
      return calcUtil.plus(calcUtil.divide(data?.shippingPS_result1 ?? data?.shippingPS_result, blIrisu), data?.shippingBL_edit);
    case "calc":
    default:
      return data?.shippingBL_calc;
  }
}
// export const getShippingPS = (data:InfoDataShipping):number => {
//   switch (data?.state) {
//     case "result":
//     case "edit_own":
//     case "edit_other":
//     case "edit_mix":
//       return calcUtil.plus(data?.shippingPS_result, data?.shippingPS_edit);
//     case "calc":
//     default:
//       return data?.shippingPS_calc;
//   }
// }

export const getBestBeforeBL = (data:InfoDataBestBefore, blIrisu:number):number => {
  switch (data?.state) {
    case "result":
    default:
      return calcUtil.divide(data?.bestBeforePS, blIrisu);
  }
}
export const getWarehousingBL = (data:InfoDataWarehousing, blIrisu:number):number => {
  switch (data?.state) {
    case "result":
    case "edit_own":
    case "edit_other":
    case "edit_mix":
    case "edit_auto":
      // return calcUtil.plus(data?.warehousingBL_result, data?.warehousingBL_edit);
      return calcUtil.sum([calcUtil.divide(data?.warehousingPS_result, blIrisu), data?.warehousingBL_if, data?.warehousingBL_edit, data?.bundleWarehousingBL]);
    case "calc":
    default:
      // return data?.warehousingBL_calc;
      return calcUtil.sum([data?.warehousingBL_calc, data?.bundleWarehousingBL]);
  }
}
export const getWarehousingBL1 = (data:InfoDataWarehousing, blIrisu:number):number => {
  switch (data?.state) {
    case "result":
    case "edit_own":
    case "edit_other":
    case "edit_mix":
    case "edit_auto":
      return calcUtil.sum([calcUtil.divide(data?.warehousingPS_result1, blIrisu), data?.warehousingBL_if, data?.warehousingBL_edit]);
    case "calc":
    default:
      return null;
  }
}
export const getWarehousingBL2 = (data:InfoDataWarehousing, blIrisu:number):number => {
  switch (data?.state) {
    case "result":
    case "edit_own":
    case "edit_other":
    case "edit_mix":
    case "edit_auto":
      return calcUtil.divide(data?.warehousingPS_result2, blIrisu);
    case "calc":
    default:
      return null;
  }
}
export const getWarehousingBL3 = (data:InfoDataWarehousing, blIrisu:number):number => {
  switch (data?.state) {
    case "result":
    case "edit_own":
    case "edit_other":
    case "edit_mix":
    case "edit_auto":
      return calcUtil.divide(data?.warehousingPS_result3, blIrisu);
    case "calc":
    default:
      return null;
  }
}

export const getTransferBL = (dataIn:InfoDataTransfer, dataOut:InfoDataTransfer):number => {
  return calcUtil.plus(
    calcUtil.minus(dataIn?.transferBL_edit, dataOut?.transferBL_edit), 
    calcUtil.plus(
      calcUtil.minus(dataIn?.transferBL_result, dataOut?.transferBL_result), 
      calcUtil.minus(dataIn?.koShippingBL, dataOut?.koShippingBL)
    ));
}
export const getTransferBL1 = (dataIn:InfoDataTransfer, dataOut:InfoDataTransfer):number => {
  return calcUtil.plus(
    calcUtil.minus(dataIn?.transferBL_edit, dataOut?.transferBL_edit), 
    calcUtil.plus(
      calcUtil.minus(dataIn?.transferBL_result1, dataOut?.transferBL_result1), 
      calcUtil.minus(dataIn?.koShippingBL, dataOut?.koShippingBL)
    ));
}
export const getTransferBL2 = (dataIn:InfoDataTransfer, dataOut:InfoDataTransfer):number => {
  return calcUtil.minus(dataIn?.transferBL_result2, dataOut?.transferBL_result2)
  ;
}
export const getTransferBL3 = (dataIn:InfoDataTransfer, dataOut:InfoDataTransfer):number => {
  return calcUtil.minus(dataIn?.transferBL_result3, dataOut?.transferBL_result3)
  ;
}

export interface TagData {
  tagID?: number | null,
  tag?: string | null,
  fgColor?: string | null,
  bgColor?: string | null,
}
export interface CommentData {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする
  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品
  keyCD?: ''|'storeSales'|'transfer'|'warehousing'|'bestBefore'|'storeOrder'|'shipping_original'|'shipping'|'shipping_storeOrder'|'stockNormal'|'stockUseUp'|'stockReturned'|string,
  state?: ''|'edit_own'|'edit_other'|'edit_mix',  //状態：実績、予測値、入力値

  comment?: string | null, //コメント
  tag?: TagData | null,

  editor?: string, //更新者
}


export interface DiffEditSeq {
  itemTagEditSeq?: number,  //取得済みEdiSew
  commentEditSeq?: number,  //取得済みEdiSew
  transferEditSeq?: number,  //取得済みEdiSew
  warehousingEditSeq?: number,  //取得済みEdiSew
  shippingEditSeq?: number,  //取得済みEdiSew
  furikaeEditSeq?: number,  //取得済みEdiSew
}
export interface DiffData {
  seq?: number,
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫
  itemCD?: string | null, //商品

  infos?: {
    // storeSales?: InfoDataStoreSales, //店頭売上
    transfer?: InfoDataTransfer, //転送予定
    warehousing?: InfoDataWarehousing, //入庫
    // bestBefore?: InfoDataBestBefore, //賞味期限
    // storeOrder?: InfoDataStoreOrder, //受注
    shipping?: InfoDataShipping, //出庫
    // stockNormal?: InfoDataStock, //在庫（良品）
    stockUseUp?: InfoDataStock, //在庫（出切）
    stockReturned?: InfoDataStock, //在庫（返品）
  },

  comment?: CommentData, //グループコメント
  comments?: {
    storeSales?: CommentData, //店頭売上
    transfer?: CommentData, //転送予定
    warehousing?: CommentData, //入庫
    bestBefore?: CommentData, //賞味期限
    storeOrder?: CommentData, //受注
    shipping_original?: CommentData, //出庫
    shipping?: CommentData, //出庫
    shipping_storeOrder?: CommentData, //出庫
    stockNormal?: CommentData, //在庫（良品）
    stockUseUp?: CommentData, //在庫（出切）
    stockReturned?: CommentData, //在庫（返品）

    similarItem_warehousing?: CommentData,
    similarItem_shipping?: CommentData,
    similarItem_stockNormal?: CommentData,  
  },

  furikae?: DiffFurikaeData,
  itemTag?: DiffItemTagData,
}

export interface DiffFurikaeData {
  ymdID?: number,//今日
  centerCD?: string, 
  itemCD?: string, 
  // zaikoKbnFrom?: '1'|'2'|'3'|'4'|'5'|string,			//在庫区分 振替元 '1'=一般、'2'=特売、'3'=予約、'4'=出切、'5'=返品
  zaikoKbnTo?: '1'|'2'|'3'|'4'|'5'|string,			//在庫区分 振替先 '1'=一般、'2'=特売、'3'=予約、'4'=出切、'5'=返品
  numBL?:number,			//振替BL数
  numPS?:number,			//振替PS数
  state: ''|'result'|'calc'|'edit_own'|'edit_other'|'edit_mix',  //状態：実績、予測値、入力値
}

export interface DiffItemTagData {
  centerCD?: string, 
  itemCD?: string, 
}


export interface ProvisionalData {
  fileType?: 'new'|'teiban'|string|null, //new:発注暫定_発売前（fm_chzm_daily ） teiabn:発注暫定_定番商品（fm_chzt_daily）
  ymdId?: number | null, //日付
  centerCD?: string | null, //倉庫
  centerCD2?: string | null, //倉庫
  centerNM?: string, 

  itemCD?: string | null, //メーカー
  itemNM?: string, 
  blIrisu?: number | null,  //容量1(BL入数) 
  csIrisu?: number | null,  //容量2(CS入数)
  lot?: number | null,  //発注単位
  csIrisu_lot?: number | null,  //CS入数×発注単位
  blIrisu_csIrisu?: string | null,  //BL入数×CS入数

  numBL?: number | null, //BL数量
}


export const centerItemAttributes:CodeName[][] = [
  [
    new CodeName({group_code:"", group_name:"", code: "", name: "(指定なし)", ryaku: "", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", ryaku: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", ryaku: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", ryaku: "都道府県", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "makerCenterName", name: "メーカー倉庫名", ryaku: "ﾒｰｶｰ倉庫", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemCD", name: "商品CD", ryaku: "商品CD", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemNM", name: "商品名", ryaku: "商品名", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "janCD", name: "JAN", ryaku: "JAN", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "maker", name: "メーカー", ryaku: "メーカー", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shiire", name: "仕入先", ryaku: "TEL", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shiireTEL", name: "仕入先TEL", ryaku: "TEL", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shiireFAX", name: "仕入先FAX", ryaku: "FAX", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "category", name: "商品カテゴリ", ryaku: "カテゴリ", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu", name: "BL入数", ryaku: "BL入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu", name: "CS入数", ryaku: "CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu_lot", name: "CS入数×発注単位", ryaku: "CS入数×発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu_csIrisu", name: "BL入数×CS入数", ryaku: "BL入数×CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "lot", name: "発注単位", ryaku: "発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "capacity", name: "内容量", ryaku: "内容量", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "bestBefore", name: "賞味期間", ryaku: "賞味期間", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "allowablePeriod", name: "許容期間", ryaku: "許容期間", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "teika", name: "定価", ryaku: "定価", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "tatene", name: "建値", ryaku: "建値", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "similarItemCD", name: "類似商品CD", ryaku: "類似商品CD", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "similarItemNM", name: "類似商品名", ryaku: "類似商品名", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "loadedQty", name: "積付数(面)", ryaku: "積付数(面)", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shubai_date", name: "終売日", ryaku: "終売日", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "tanaban", name: "棚番", ryaku: "棚番", option2:"55"}),  //SEJ非表示
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "zaikoKigen", name: "在庫賞味期限", ryaku: "在庫賞味期限", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "zaikoUketsuke", name: "在庫受付日", ryaku: "在庫受付日", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "zaikoKigenAlert", name: "許容アラート賞味期限", ryaku: "許容アラート賞味期限", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiCD", name: "得意先CD", ryaku: "得意先CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiNM", name: "得意先名", ryaku: "得意先名", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiItemCD", name: "得意先商品CD", ryaku: "得意先商品CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "teibanTokuisakiCD", name: "定番得意先CD", ryaku: "定番得意先CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "teibanTokuisakiNM", name: "定番得意先名", ryaku: "定番得意先名", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "teibanTokuisakiItemCD", name: "定番得意先商品CD", ryaku: "定番得意先商品CD", }),
    new CodeName({group_code:"bundle", group_name:"バンドル", code: "bundleItemCD", name: "バンドル商品CD", ryaku: "バンドル商品CD", }),
    new CodeName({group_code:"bundle", group_name:"バンドル", code: "bundledItemCD", name: "被バンドル商品CD", ryaku: "被バンドル商品CD", }),
    new CodeName({group_code:"bundle", group_name:"バンドル", code: "bundleRate", name: "バンドル比率", ryaku: "バンドル比率", }),
    new CodeName({group_code:"oyako", group_name:"親子センター", code: "oyaCenter", name: "親センター", ryaku: "親センター", option1: "31,55", }),
    new CodeName({group_code:"oyako", group_name:"親子センター", code: "koCenter", name: "子センター", ryaku: "子センター", option1: "31,55", }),
  ],
  [
    new CodeName({group_code:"", group_name:"", code: "", name: "(指定なし)", ryaku: "", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", ryaku: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", ryaku: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", ryaku: "都道府県", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "makerCenterName", name: "メーカー倉庫名", ryaku: "ﾒｰｶｰ倉庫", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemCD", name: "商品CD", ryaku: "商品CD", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "itemNM", name: "商品名", ryaku: "商品名", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "janCD", name: "JAN", ryaku: "JAN", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "maker", name: "メーカー", ryaku: "メーカー", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shiire", name: "仕入先", ryaku: "仕入先", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shiireTEL", name: "仕入先TEL", ryaku: "TEL", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shiireFAX", name: "仕入先FAX", ryaku: "FAX", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "category", name: "商品カテゴリ", ryaku: "カテゴリ", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu", name: "BL入数", ryaku: "BL入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu", name: "CS入数", ryaku: "CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "csIrisu_lot", name: "CS入数×発注単位", ryaku: "CS入数×発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "blIrisu_csIrisu", name: "BL入数×CS入数", ryaku: "BL入数×CS入数", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "loadedQty", name: "積付数(面)", ryaku: "積付数(面)", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "lot", name: "発注単位", ryaku: "発注単位", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "capacity", name: "内容量", ryaku: "内容量", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "bestBefore", name: "賞味期間", ryaku: "賞味期間", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "allowablePeriod", name: "許容期間", ryaku: "許容期間", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "teika", name: "定価", ryaku: "定価", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "tatene", name: "建値", ryaku: "建値", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "similarItemCD", name: "類似商品CD", ryaku: "類似商品CD", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "similarItemNM", name: "類似商品名", ryaku: "類似商品名", }),
    new CodeName({group_code:"item", group_name:"商品属性", code: "shubai_date", name: "終売日", ryaku: "終売日", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "tanaban", name: "棚番", ryaku: "棚番", option2:"55"}),  //SEJ非表示
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "zaikoKigen", name: "在庫賞味期限", ryaku: "在庫賞味期限", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "zaikoUketsuke", name: "在庫受付日", ryaku: "在庫受付日", }),
    new CodeName({group_code:"zaiko", group_name:"在庫属性", code: "zaikoKigenAlert", name: "許容アラート賞味期限", ryaku: "許容アラート賞味期限", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiCD", name: "得意先CD", ryaku: "得意先CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiNM", name: "得意先名", ryaku: "得意先名", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "tokuisakiItemCD", name: "得意先商品CD", ryaku: "得意先商品CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "teibanTokuisakiCD", name: "定番得意先CD", ryaku: "定番得意先CD", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "teibanTokuisakiNM", name: "定番得意先名", ryaku: "定番得意先名", }),
    new CodeName({group_code:"teiban", group_name:"定番マスタ", code: "teibanTokuisakiItemCD", name: "定番得意先商品CD", ryaku: "定番得意先商品CD", }),
    new CodeName({group_code:"bundle", group_name:"バンドル", code: "bundleItemCD", name: "バンドル商品CD", ryaku: "バンドル商品CD", }),
    new CodeName({group_code:"bundle", group_name:"バンドル", code: "bundledItemCD", name: "被バンドル商品CD", ryaku: "被バンドル商品CD", }),
    new CodeName({group_code:"bundle", group_name:"バンドル", code: "bundleRate", name: "バンドル比率", ryaku: "バンドル比率", }),
    new CodeName({group_code:"oyako", group_name:"親子センター", code: "oyaCenter", name: "親センター", ryaku: "親センター", option1: "31,55", }),  //FMのみ表示
    new CodeName({group_code:"oyako", group_name:"親子センター", code: "koCenter", name: "子センター", ryaku: "子センター", option1: "31,55", }),  //FMのみ表示
  ]
];

//並び順
//option1 : 親グループ
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"item", group_name:"商品→センター", code: "itemCD", name: "商品コード順", option1: "maker",}),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "specifiedOrder", name: "指定商品順", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "itemNM", name: "商品名順", option1: "", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "janCD", name: "JANコード順", option1: "", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "maker", name: "メーカー順", option1: "maker", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "category", name: "商品カテゴリ順", option1: "", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "bestBefore", name: "賞味期間順", option1: "", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "teika", name: "定価順", option1: "", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "tatene", name: "建値順", option1: "", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "shubai_date", name: "終売日順", option1: "", }),
  new CodeName({group_code:"item", group_name:"商品→センター", code: "tanaban_item", name: "棚番順（１倉庫のみ）", option1: "", }),  
  new CodeName({group_code:"item", group_name:"商品→センター", code: "maker_tanaban", name: "メーカー→棚番順（１倉庫のみ）", option1: "maker", }),

  new CodeName({group_code:"center", group_name:"センター→商品", code: "center", name: "倉庫コード→商品コード", option1: "", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "tanaban", name: "倉庫コード→棚番", option1: "", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "area", name: "地域順", option1: "", }),
  new CodeName({group_code:"center", group_name:"センター→商品", code: "pref", name: "都道府県順", option1: "", }),

];



//年月日
export type YMDInfo = {
  ymdID: number | null, //年月日 or 年週
  type: 'today' | 'past' | 'past_hidden' | 'future',
  holidayDiv: 'saturday' | 'sunday' | 'holiday',
  week_id: number,  //週識別子
  week: boolean,  //週
  infos: {
    storeSales?: InfoDataStoreSales, //店頭売上
    transferIn?: InfoDataTransfer, //転送予定(転入)
    transferOut?: InfoDataTransfer, //転送予定(転出)
    warehousing?: InfoDataWarehousing, //入庫
    warehousing_last?: InfoDataWarehousing, //入庫
    order?: InfoDataOrder, //発注
    bestBefore?: InfoDataBestBefore, //賞味期限
    storeOrder?: InfoDataStoreOrder, //受注
    shipping?: InfoDataShipping, //出庫
    shipping_last?: InfoDataShipping, //出庫
    shipping_group1s?: any, //出庫  //{group2_group1: InfoDataShippingGroup1}
    stock?: InfoDataStock, //在庫（良品）
    weather?: OpenMeteoDaily, //天気

    similarItem_warehousing?: InfoDataWarehousing,
    similarItem_shipping?: InfoDataShipping,
    similarItem_stock?: InfoDataStock,
    similarItem_storeOrder?: InfoDataStoreOrder, //受注
  }
  comments?: {
    storeSales?: CommentData, //店頭売上
    transfer?: CommentData, //転送予定
    warehousing?: CommentData, //入庫
    warehousing_last?: CommentData, //入庫
    bestBefore?: CommentData, //賞味期限
    storeOrder?: CommentData, //受注
    shipping_original?: CommentData, //出庫
    shipping?: CommentData, //出庫
    shipping_storeOrder?: CommentData, //出庫
    shipping_last?: CommentData, //出庫
    stockNormal?: CommentData, //在庫（良品）
    stockUseUp?: CommentData, //在庫（出切）
    stockReturned?: CommentData, //在庫（返品）

    similarItem_warehousing?: CommentData,
    similarItem_shipping?: CommentData,
    similarItem_stockNormal?: CommentData,  
  },
  // errorInfo?: ErrorInfoData,  //エラー情報
  highlightInfo?: HighlightInfoData,  //ハイライト情報
}

export type RowDataGroup = {
  ymdInfos: YMDInfo[],
  centerItem: CenterItem,

  makerTagData?: MakerTagData,
  makerLimitData?: MakerLimitData,

  itemCommonData?: ItemCommonData,
  disableDateData?:DisableDateData,

  itemTagDatas?: ItemTagData[],
  itemTag_NEW?: ItemTagData,
  itemTag_CPN?: ItemTagData,
  itemTag_CUT?: ItemTagData,
  itemTag_EMC?: ItemTagData,
  itemTag_PKG?: ItemTagData,
  itemTag_OTH?: ItemTagData,

  comments?: {}, //コメント //Map形式{index:CommentData}

  hasAlert?: boolean, //検索条件のアラート該当

  //表示フラグ
  displayed?: boolean,
  //検索済みフラグ
  searchedItemTags?: boolean,
  searchedMakerTags?: boolean,
  searchedComments?: boolean,
  searchedResults?: boolean,

  //ドリルダウンヘッダー
  shipping_group1s_key?: string[],
  shipping_group1s_name?: any, 
  
  calced1?: boolean, //計算済み
  calced2?: boolean, //計算済み
  calced3?: boolean, //計算済み
  checked?: boolean, //チェック済み
  highlighted?: boolean, //ハイライト設定済み

  freshnessAlert?: boolean, //許容期限切れアラート
  freshnessAlertBL?: number, //許容期限切れアラートBL数
  weatherAlert?: boolean, //悪天候アラート
}

//背景色ハイライト情報
export interface HighlightInfoData {
  //ハイライト内容
  // storeSales_text?: string | null, // 店頭売上	
  // transfer_text?: string | null, // 転送予定	
  warehousing_text?: string | null, // 入庫	
  // bestBefore_text?: string | null, // 賞味期限	
  storeOrder_text?: string | null, // 受注	
  // shipping_original_text?: string | null, // 出庫	
  shipping_text?: string | null, // 出庫	
  shipping_storeOrder_text?: string | null, // 出庫(先付含)
  stockNormal_text?: string | null, // 在庫（良品）	
  // stockUseUp_text?: string | null, // 在庫（出切）	
  // stockReturned_text?: string | null, // 在庫（返品）	
  //ハイライトCSS
  // storeSales_css?: string | null, // 店頭売上	
  // transfer_css?: string | null, // 転送予定	
  warehousing_css?: string | null, // 入庫	
  // bestBefore_css?: string | null, // 賞味期限	
  storeOrder_css?: string | null, // 受注	
  // shipping_original_css?: string | null, // 出庫	
  shipping_css?: string | null, // 出庫	
  shipping_storeOrder_css?: string | null, // 出庫(先付含)
  stockNormal_css?: string | null, // 在庫（良品）	
  // stockUseUp_css?: string | null, // 在庫（出切）	
  // stockReturned_css?: string | null, // 在庫（返品）	

}
//計算
export const calcDataGroups = (dataGroups:RowDataGroup[], rowKeys:string[], ymds: YMD[], fromIndex:number, toIndex:number, notCalcPast:boolean, notShippingCalc:boolean, averageWeeks: number): RowDataGroup[] => {
  return dataGroups
    .map(dataGroup => calcDataGroup1(dataGroup, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, null, averageWeeks))
    .map(dataGroup => calcDataGroup2(dataGroup, dataGroups, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, null))
    .map(dataGroup => calcDataGroup3(dataGroup, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, null))
    ;
}

export const calcDataGroupSingle = (dataGroup:RowDataGroup, rowKeys:string[], ymds: YMD[], fromIndex:number, toIndex:number, notCalcPast:boolean, notShippingCalc:boolean, editRowKeysSet:Set<string>, averageWeeks: number): RowDataGroup => {
  dataGroup = calcDataGroup1(dataGroup, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, editRowKeysSet, averageWeeks);
  // dataGroup = calcDataGroup2(dataGroup, dataGroups, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, editRowKeysSet);
  dataGroup = calcDataGroup3(dataGroup, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, editRowKeysSet);
  return dataGroup;
}
export const calcDataGroupAll = (dataGroup:RowDataGroup, dataGroups:RowDataGroup[], rowKeys:string[], ymds: YMD[], fromIndex:number, toIndex:number, notCalcPast:boolean, notShippingCalc:boolean, editRowKeysSet:Set<string>, averageWeeks: number): RowDataGroup => {
  dataGroup = calcDataGroup1(dataGroup, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, editRowKeysSet, averageWeeks);
  dataGroup = calcDataGroup2(dataGroup, dataGroups, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, editRowKeysSet);
  dataGroup = calcDataGroup3(dataGroup, rowKeys, ymds, fromIndex, toIndex, notCalcPast, notShippingCalc, editRowKeysSet);
  return dataGroup;
}

//単体の計算。先にこちらを行う
export const calcDataGroup1 = (dataGroup:RowDataGroup, rowKeys:string[], ymds: YMD[], fromIndex:number, toIndex:number, notCalcPast:boolean, notShippingCalc:boolean, editRowKeysSet:Set<string>, averageWeeks: number): RowDataGroup => {
  if(!dataGroup?.centerItem) {
    return dataGroup;
  }
  //未検索はreturn
  if(!dataGroup.searchedResults) {
    return dataGroup;
  }
  //計算済みはreturn
  if(dataGroup.calced1) {
    return dataGroup;
  }
  //

  const centerItem = dataGroup.centerItem;
  const blIrisu = centerItem.blIrisu;
  const targetYmds = ymds.filter((ymd, index) => fromIndex <= index && index <= toIndex);

  //計算順序
  const rowKeysOrder = [
    "similarItem_warehousing",  //: "[類似商品]入庫",
    "similarItem_shipping",     //"[類似商品]出庫",
    // "similarItem_stockNormal",  //"[類似商品]在庫（良品）",

    "storeSales",   //店頭売上",
    "bestBefore",   //賞味期限",
    "storeOrder",   //受注",

    "warehousing",  //入庫",
    "warehousing_last",  //前年入庫",
    "shipping_original",     //出庫",
    "shipping",     //出庫",
    "shipping_storeOrder",     //出庫",
    "shipping_last",     //前年出庫",
    "transfer",     //転送予定",
    // "stockNormal",  //在庫（良品）",
    "stockUseUp",   //在庫（出切）",
    "stockReturned",  //在庫（返品）",
    "weather",
  ];  
  const rowKeyCopy = [...rowKeys];
  rowKeyCopy.sort((a:string, b:string) => {
    const ao = rowKeysOrder.indexOf(a);
    const bo = rowKeysOrder.indexOf(b);
    return ao - bo;
  });

  rowKeyCopy.forEach(rowKey => {
    switch (rowKey) {
      case 'storeSales':
        break;
      case 'transfer':
        //子センターならば転送に値をセットする
        const transferCalcFunc = (ymd:YMD, dataGroup:RowDataGroup) => {
          const ymdIndex = ymd.index;
          const ymdInfo = dataGroup.ymdInfos[ymdIndex];
          if(ymdInfo.infos.transferIn) {
            ymdInfo.infos.transferIn.koShippingBL = null;  //いったんクリア
          }
          if(!dataGroup) {
            return;
          }
          const koYmdInfo = dataGroup?.ymdInfos?.[ymdIndex];
          if(!koYmdInfo) {
            return;
          }
          const koShippingBL = getShippingBL(koYmdInfo.infos.shipping, blIrisu);
          if(!koShippingBL) {
            return;
          }
          //無は初期化
          if(!ymdInfo.infos.transferIn) {
            ymdInfo.infos.transferIn = {
              ymdID: ymdInfo.ymdID, //年月日
              centerCD: centerItem.centerCD, //倉庫
              itemCD: centerItem.itemCD, //商品
            };
          }
          ymdInfo.infos.transferIn.koShippingBL = koShippingBL; 
        };

        if(centerItem.oyaCenter1) {
          targetYmds
            .filter(ymd => ymd.type != 'past' && centerItem.oyaCenter1.fromYMD <= ymd.ymdID && ymd.ymdID <= centerItem.oyaCenter1.toYMD)
            .forEach(ymd => {
              transferCalcFunc(ymd, dataGroup);
            });
        }
        if(centerItem.oyaCenter2) {
          targetYmds
            .filter(ymd => ymd.type != 'past' && centerItem.oyaCenter2.fromYMD <= ymd.ymdID && ymd.ymdID <= centerItem.oyaCenter2.toYMD)
            .forEach(ymd => {
              transferCalcFunc(ymd, dataGroup);
            });
        }
        if(centerItem.oyaCenter3) {
          targetYmds
            .filter(ymd => ymd.type != 'past' && centerItem.oyaCenter3.fromYMD <= ymd.ymdID && ymd.ymdID <= centerItem.oyaCenter3.toYMD)
            .forEach(ymd => {
              transferCalcFunc(ymd, dataGroup);
            });
        }

        break;
      case 'warehousing':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroup.ymdInfos[ymdIndex];
          switch (ymd.type) {
            case "past":
              //未編集の初回表示時のみ計算する
              //値がない場合。同日のresult在庫があれば締め済み。３本締めされておらず発注があればその値を利用する
              if(!notCalcPast && 
                !ymdInfo.infos?.warehousing?.warehousingPS_result && //入庫がない
                ymdInfo.infos?.stock?.stateNormal != 'result' && //3本締めされていない
                ymdInfo.infos?.warehousing?.warehousingBL_plan //発注がある
              ) {
                //無は初期化
                if(!ymdInfo.infos.warehousing) {
                  ymdInfo.infos.warehousing = {
                    ymdID: ymdInfo.ymdID, //年月日
                    centerCD: centerItem.centerCD, //倉庫
                    itemCD: centerItem.itemCD, //商品
                  };
                }

                // ymdInfo.infos.shipping.shippingPS_result = calcUtil.multiply(ymdInfo.infos.storeOrder.storeOrderBL, blIrisu);
                // ymdInfo.infos.shipping.state = "result";
                ymdInfo.infos.warehousing.warehousingBL_calc = ymdInfo.infos.warehousing.warehousingBL_plan;
                ymdInfo.infos.warehousing.state = "calc";
              }
              break;
            case "today":
              break;
            case "future":
              break;
            default:
              break;
          }
        });
        break;
      case 'bestBefore':
        break;
      case 'storeOrder':
        break;
      case 'shipping_original':
        break;
      case 'shipping':
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroup.ymdInfos[ymdIndex];
          const ymdInfoYesterday = dataGroup.ymdInfos[ymd.yesterdayIndex];
          switch (ymd.type) {
            case "past":
              //未編集の初回表示時のみ計算する
              //値がない場合。同日のresult在庫があれば締め済み。３本締めされておらず受注があればその値を利用する
              if(!notCalcPast && 
                !ymdInfo.infos?.shipping?.shippingPS_result && //出庫がない
                ymdInfo.infos?.stock?.stateNormal != 'result' && //3本締めされていない
                ymdInfo.infos?.storeOrder?.storeOrderPS //受注がある
              ) {
                //無は初期化
                if(!ymdInfo.infos.shipping) {
                  ymdInfo.infos.shipping = {
                    ymdID: ymdInfo.ymdID, //年月日
                    centerCD: centerItem.centerCD, //倉庫
                    itemCD: centerItem.itemCD, //商品
                  };
                }
                ymdInfo.infos.shipping.shippingBL_calc = getNumBLfromPS(ymdInfo.infos.storeOrder.storeOrderPS, blIrisu);
                ymdInfo.infos.shipping.state = "calc";
              }
              break;
            case "today":
              const yesterdayStockNormalBL = ymdInfoYesterday?.infos?.stock?.stockNormalBL;
              const shippingBL = getShippingBL(ymdInfo.infos.shipping, blIrisu);
              if (dataGroup.centerItem.shippingJuchuOrInput) {
                if (!ymdInfo.infos.shipping) {
                  ymdInfo.infos.shipping = {
                    ymdID: ymdInfo.ymdID, //年月日
                    centerCD: centerItem.centerCD, //倉庫
                    itemCD: centerItem.itemCD, //商品
                  };
                  //(計算)過去3週平均
                  const averageYmdInfos = [];
                  let tmpYmd = ymd;
                  while (tmpYmd && tmpYmd.lastWeekIndex >= 0 && averageYmdInfos.length < averageWeeks) {
                    const tmpYmdInfo = dataGroup?.ymdInfos?.[tmpYmd.lastWeekIndex];
                    if (tmpYmdInfo) {
                      averageYmdInfos.push(tmpYmdInfo);
                    }
                    tmpYmd = ymds[tmpYmd.lastWeekIndex];
                  }

                  let shippingBL;
                  //類似商品アリの場合
                  if (centerItem.similarItemCD) {
                    const bls = averageYmdInfos.map(ymdInfo2 => getShippingBL(ymdInfo2?.infos.similarItem_shipping, blIrisu));
                    shippingBL = Math.round(calcUtil.average(bls, true));
                  }
                  //類似商品なしの場合
                  else {
                    const bls = averageYmdInfos.map(ymdInfo2 => getShippingBL(ymdInfo2?.infos.shipping, blIrisu));
                    shippingBL = Math.round(calcUtil.average(bls, true));
                  }
                  if (!shippingBL) shippingBL = null;
                  ymdInfo.infos.shipping.shippingPS_result = getNumPS(shippingBL, blIrisu);
                }
                ymdInfo.infos.shipping.state = "result";
              }
              else if (dataGroup.centerItem.todayUseFlg && yesterdayStockNormalBL < shippingBL) {
                //前日の在庫分のみを使用する
                ymdInfo.infos.shipping.shippingBL_calc = yesterdayStockNormalBL;
                ymdInfo.infos.shipping.state = "calc";
              }
              break;
            case "future":
              //未計算のみ計算する
              if(!notShippingCalc && ["result", "edit_own", "edit_other", "edit_mix", "calc"].indexOf(ymdInfo?.infos?.shipping?.state) == -1) {
                //(計算)過去3週平均
                const averageYmdInfos = [];
                let tmpYmd = ymd;
                while(tmpYmd && tmpYmd.lastWeekIndex >= 0 && averageYmdInfos.length < averageWeeks) {
                  const tmpYmdInfo = dataGroup?.ymdInfos?.[tmpYmd.lastWeekIndex];
                  if(tmpYmdInfo) {
                    averageYmdInfos.push(tmpYmdInfo);
                  }
                  tmpYmd = ymds[tmpYmd.lastWeekIndex];
                }
                let shippingBL;
                //類似商品アリの場合
                if(centerItem.similarItemCD) {
                  const bls = averageYmdInfos.map(ymdInfo2 => getShippingBL(ymdInfo2?.infos.similarItem_shipping, blIrisu));
                  shippingBL = Math.round(calcUtil.average(bls, true));
                }
                //類似商品なしの場合
                else {
                  const bls = averageYmdInfos.map(ymdInfo2 => getShippingBL(ymdInfo2?.infos.shipping, blIrisu));
                  shippingBL = Math.round(calcUtil.average(bls, true));
                }
                if(!shippingBL) shippingBL = null;
                ymdInfo.infos.shipping.shippingBL_calc = shippingBL;
                ymdInfo.infos.shipping.state = "calc";
                if(editRowKeysSet) editRowKeysSet.add(rowKey);  //再描写対象として記録
              }
              break;
            default:
              break;
          }
        });
        break;
      case 'shipping_storeOrder':
        break;
      // case 'stockNormal':
      //   break;
      case 'stockUseUp':
        break;
      case 'stockReturned':
        break;
      case "similarItem_warehousing":
        break;
      case "similarItem_shipping":
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroup.ymdInfos[ymdIndex];
          switch (ymd.type) {
            case "past":
              //未編集の初回表示時のみ計算する
              //値がない場合。同日のresult在庫があれば締め済み。３本締めされておらず受注があればその値を利用する
              if(!notCalcPast && 
                !ymdInfo.infos?.similarItem_shipping?.shippingPS_result && //出庫がない
                ymdInfo.infos?.similarItem_stock?.stateNormal != 'result' && //3本締めされていない
                ymdInfo.infos?.similarItem_storeOrder?.storeOrderPS //受注がある
              ) {
                //無は初期化
                if(!ymdInfo.infos.similarItem_shipping) {
                  ymdInfo.infos.similarItem_shipping = {
                    ymdID: ymdInfo.ymdID, //年月日
                    centerCD: centerItem.centerCD, //倉庫
                    itemCD: centerItem.itemCD, //商品
                  };
                }

                ymdInfo.infos.similarItem_shipping.shippingBL_calc = getNumBLfromPS(ymdInfo.infos.similarItem_storeOrder.storeOrderPS, blIrisu);
                ymdInfo.infos.similarItem_shipping.state = "calc";
              }
              break;
            case "today":
              break;
            case "future":
              break;
            default:
              break;
          }
        });

        break;
      // case "similarItem_stockNormal":
      //   break;
      default:
        break;
    }

  });
  dataGroup.calced1 = true;
  return dataGroup;
}
//他レコードを参照しての計算。あとでこちらを行う
export const calcDataGroup2 = (dataGroup:RowDataGroup, dataGroups:RowDataGroup[], rowKeys:string[], ymds: YMD[], fromIndex:number, toIndex:number, notCalcPast:boolean, notShippingCalc:boolean, editRowKeysSet:Set<string>): RowDataGroup => {
  if(!dataGroup?.centerItem) {
    return dataGroup;
  }
  //未検索はreturn
  if(!dataGroup.searchedResults) {
    return dataGroup;
  }
  //計算済みはreturn
  if(dataGroup.calced2) {
    return dataGroup;
  }
  //

  const centerItem = dataGroup.centerItem;
  const blIrisu = centerItem.blIrisu;
  const targetYmds = ymds.filter((ymd, index) => fromIndex <= index && index <= toIndex);

  //計算順序
  const rowKeysOrder = [
    // "similarItem_warehousing",  //: "[類似商品]入庫",
    // "similarItem_shipping",     //"[類似商品]出庫",
    // "similarItem_stockNormal",  //"[類似商品]在庫（良品）",

    // "storeSales",   //店頭売上",
    // "bestBefore",   //賞味期限",
    // "storeOrder",   //受注",

    // "warehousing",  //入庫",
    // "shipping",     //出庫",
    // "shipping_storeOrder",     //出庫",
    "transfer",     //転送予定",
    // "stockNormal",  //在庫（良品）",
    // "stockUseUp",   //在庫（出切）",
    // "stockReturned",  //在庫（返品）",
  ];  
  const rowKeyCopy = [...rowKeys];
  rowKeyCopy.sort((a:string, b:string) => {
    const ao = rowKeysOrder.indexOf(a);
    const bo = rowKeysOrder.indexOf(b);
    return ao - bo;
  });

  rowKeyCopy.forEach(rowKey => {
    switch (rowKey) {
      // case 'storeSales':
      //   break;
      case 'transfer':
        //子センターの分を追加する
        const transferCalcFunc = (ymd:YMD, koCenerDataGroup1:RowDataGroup, koCenerDataGroup2:RowDataGroup, koCenerDataGroup3:RowDataGroup) => {
          if(!koCenerDataGroup1 && !koCenerDataGroup2 && !koCenerDataGroup3) {
            return;
          }
          const ymdIndex = ymd.index;
          const ymdInfo = dataGroup.ymdInfos[ymdIndex];
          if(ymdInfo.infos.transferOut) {
            ymdInfo.infos.transferOut.koShippingBL = null;  //いったんクリア
          }
          const koYmdInfo1 = koCenerDataGroup1?.ymdInfos?.[ymdIndex];
          const koYmdInfo2 = koCenerDataGroup2?.ymdInfos?.[ymdIndex];
          const koYmdInfo3 = koCenerDataGroup3?.ymdInfos?.[ymdIndex];
          if(!koYmdInfo1 && !koYmdInfo2 && !koYmdInfo3) {
            return;
          }
          const koShippingBL1 = !koYmdInfo1 ? null :getShippingBL(koYmdInfo1.infos.shipping, blIrisu);
          const koShippingBL2 = !koYmdInfo2 ? null :getShippingBL(koYmdInfo2.infos.shipping, blIrisu);
          const koShippingBL3 = !koYmdInfo3 ? null :getShippingBL(koYmdInfo3.infos.shipping, blIrisu);
          if(!koShippingBL1 && !koShippingBL2 && !koShippingBL3) {
            return;
          }
          //無は初期化
          if(!ymdInfo.infos.transferOut) {

            ymdInfo.infos.transferOut = {
              ymdID: ymdInfo.ymdID, //年月日
              centerCD: centerItem.centerCD, //倉庫
              itemCD: centerItem.itemCD, //商品
            };
          }
          ymdInfo.infos.transferOut.koShippingBL = (koShippingBL1 ?? 0) + (koShippingBL2 ?? 0) + (koShippingBL3 ?? 0);
        };

        if(centerItem.koCenter1 || centerItem.koCenter2 || centerItem.koCenter3) {
          let koCenerDataGroup1:RowDataGroup;
          let koCenerDataGroup2:RowDataGroup;
          let koCenerDataGroup3:RowDataGroup;
          targetYmds
          .filter(ymd => ymd.type != 'past' && (
            (centerItem.koCenter1 && centerItem.koCenter1.fromYMD <= ymd.ymdID && ymd.ymdID <= centerItem.koCenter1.toYMD) ||
            (centerItem.koCenter2 && centerItem.koCenter2.fromYMD <= ymd.ymdID && ymd.ymdID <= centerItem.koCenter2.toYMD) ||
            (centerItem.koCenter3 && centerItem.koCenter3.fromYMD <= ymd.ymdID && ymd.ymdID <= centerItem.koCenter3.toYMD)
          ))
          .forEach(ymd => {
            if(!koCenerDataGroup1 || koCenerDataGroup1.centerItem.itemCD != centerItem.itemCD || koCenerDataGroup1.centerItem.centerCD != centerItem.koCenter1?.code) {
              const koCenerDataGroupIndex = findDataGroupIndex(centerItem.itemCD, centerItem.koCenter1?.code, dataGroups);
              koCenerDataGroup1 = dataGroups[koCenerDataGroupIndex];
            }
            if(!koCenerDataGroup2 || koCenerDataGroup2.centerItem.itemCD != centerItem.itemCD || koCenerDataGroup2.centerItem.centerCD != centerItem.koCenter2?.code) {
              const koCenerDataGroupIndex = findDataGroupIndex(centerItem.itemCD, centerItem.koCenter2?.code, dataGroups);
              koCenerDataGroup2 = dataGroups[koCenerDataGroupIndex];
            }
            if(!koCenerDataGroup3 || koCenerDataGroup3.centerItem.itemCD != centerItem.itemCD || koCenerDataGroup3.centerItem.centerCD != centerItem.koCenter3?.code) {
              const koCenerDataGroupIndex = findDataGroupIndex(centerItem.itemCD, centerItem.koCenter3?.code, dataGroups);
              koCenerDataGroup3 = dataGroups[koCenerDataGroupIndex];
            }
            transferCalcFunc(ymd, koCenerDataGroup1, koCenerDataGroup2, koCenerDataGroup3);
          });
        }
        break;
      case 'warehousing':
        //バンドル分を追加する
        const warehousingCalcFunc = (ymd:YMD, bundledDataGroup:RowDataGroup) => {
          if(!bundledDataGroup) {
            return;
          }
          const ymdIndex = ymd.index;
          const ymdInfo = dataGroup.ymdInfos[ymdIndex];
          if(ymdInfo.infos.warehousing) {
            ymdInfo.infos.warehousing.bundleWarehousingBL = null;  //いったんクリア
          }
          const bundledYmdInfo = bundledDataGroup?.ymdInfos?.[ymdIndex];
          if(!bundledYmdInfo) {
            return;
          }
          const bundleWarehousingBL = !bundledYmdInfo ? null :Math.trunc(getWarehousingBL(bundledYmdInfo.infos.warehousing, bundledDataGroup?.centerItem?.blIrisu) / bundledDataGroup?.centerItem?.bundleRate * bundledDataGroup?.centerItem?.blIrisu / centerItem?.blIrisu);
          if(!bundleWarehousingBL) {
            return;
          }
          //無は初期化
          if(!ymdInfo.infos.warehousing) {
            ymdInfo.infos.warehousing = {
              ymdID: ymdInfo.ymdID, //年月日
              centerCD: centerItem.centerCD, //倉庫
              itemCD: centerItem.itemCD, //商品
            };
          }
          ymdInfo.infos.warehousing.bundleWarehousingBL = (bundleWarehousingBL ?? 0);
        };

        if(centerItem.bundledItemCD) {
          let bundledDataGroup:RowDataGroup;
          targetYmds
          .filter(ymd => centerItem.bundledItemCD)
          .forEach(ymd => {
            if(!bundledDataGroup || bundledDataGroup.centerItem.centerCD != centerItem.centerCD || bundledDataGroup.centerItem.itemCD != centerItem.bundledItemCD) {
              const bundledDataGroupIndex = findDataGroupIndex(centerItem.bundledItemCD, centerItem.centerCD, dataGroups);
              bundledDataGroup = dataGroups[bundledDataGroupIndex];
            }
            warehousingCalcFunc(ymd, bundledDataGroup);
          });
        }
        break;
      // case 'bestBefore':
      //   break;
      // case 'storeOrder':
      //   break;
      // case 'shipping':
      //   break;
      // case 'shipping_storeOrder':
      //   break;
      // case 'stockNormal':
      //   break;
      // case 'stockUseUp':
      //   break;
      // case 'stockReturned':
      //   break;
      // case "similarItem_warehousing":
      //   break;
      // case "similarItem_shipping":
      //   break;
      // case "similarItem_stockNormal":
      //   break;
      default:
        break;
    }

  });
  dataGroup.calced2 = true;
  return dataGroup;
}
//単体の計算。最後にこちらを行う
export const calcDataGroup3 = (dataGroup:RowDataGroup, rowKeys:string[], ymds: YMD[], fromIndex:number, toIndex:number, notCalcPast:boolean, notShippingCalc:boolean, editRowKeysSet:Set<string>): RowDataGroup => {
  if(!dataGroup?.centerItem) {
    return dataGroup;
  }
  //未検索はreturn
  if(!dataGroup.searchedResults) {
    return dataGroup;
  }
  //計算済みはreturn
  if(dataGroup.calced3) {
    return dataGroup;
  }
  //

  const centerItem = dataGroup.centerItem;
  const blIrisu = centerItem.blIrisu;
  const targetYmds = ymds.filter((ymd, index) => fromIndex <= index && index <= toIndex);

  //計算順序
  const rowKeysOrder = [
    // "similarItem_warehousing",  //: "[類似商品]入庫",
    // "similarItem_shipping",     //"[類似商品]出庫",
    "similarItem_stockNormal",  //"[類似商品]在庫（良品）",

    // "storeSales",   //店頭売上",
    // "bestBefore",   //賞味期限",
    // "storeOrder",   //受注",

    // "warehousing",  //入庫",
    // "shipping",     //出庫",
    // "shipping_storeOrder",     //出庫",
    // "transfer",     //転送予定",
    "stockNormal",  //在庫（良品）",
    "stockNormal1",  //在庫（一般）",※良品でまとめて計算する
    "stockNormal2",  //在庫（特売）",※良品でまとめて計算する
    "stockNormal3",  //在庫（予約）",※良品でまとめて計算する
    // "stockUseUp",   //在庫（出切）",
    // "stockReturned",  //在庫（返品）",
  ];  
  const rowKeyCopy = [...rowKeys];
  rowKeyCopy.sort((a:string, b:string) => {
    const ao = rowKeysOrder.indexOf(a);
    const bo = rowKeysOrder.indexOf(b);
    return ao - bo;
  });

  rowKeyCopy.forEach(rowKey => {
    switch (rowKey) {
      // case 'storeSales':
      //   break;
      // case 'transfer':
      //   break;
      // case 'warehousing':
      //   break;
      // case 'bestBefore':
      //   break;
      // case 'storeOrder':
      //   break;
      // case 'shipping':
      //   break;
      // case 'shipping_storeOrder':
      //   break;
      case 'stockNormal':
        {
          let hasPastResult = false;
          targetYmds.forEach((ymd, index) => {
            //週はreturn
            if(ymd.week) {
              return;
            }
            const ymdIndex = index + fromIndex;
            const ymdInfo = dataGroup.ymdInfos[ymdIndex];
            const ymdInfoYesterday = dataGroup.ymdInfos[ymd.yesterdayIndex];
            switch (ymd.type) {
              case "past":
                //過去在庫がない場合は計算対象外
                if(!hasPastResult && ymdInfo.infos?.stock?.stateNormal == 'result') {
                  hasPastResult = true;
                }
                //未編集の初回表示時のみ計算する
                //値がない場合。同日のresult在庫があれば締め済み。３本締めされてなければ計算する
                //(計算)前日在庫+入庫-出庫-当日振替
                if(!notCalcPast && 
                  ymdInfo.infos?.stock?.stateNormal != 'result' //3本締めされていない
                ) {
                  //無は初期化
                  if(!ymdInfo.infos.stock) {
                    ymdInfo.infos.stock = {
                      ymdID: ymdInfo.ymdID, //年月日
                      centerCD: centerItem.centerCD, //倉庫
                      itemCD: centerItem.itemCD, //商品
                    };
                  }

                  if(hasPastResult) {
                    //良品
                    {
                      let stockNormalBL_past = 
                        calcUtil.plus(
                          calcUtil.minus(
                            calcUtil.minus(
                              calcUtil.minus(
                                calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormalBL, getWarehousingBL(ymdInfo?.infos?.warehousing, blIrisu)), 
                              getShippingBL1(ymdInfo?.infos?.shipping, blIrisu)),
                            ymdInfo?.infos?.stock?.stockUseUpBL),
                          ymdInfo?.infos?.stock?.stockReturnedBL),
                        getTransferBL(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                      ymdInfo.infos.stock.stockNormalBL = stockNormalBL_past;
                    }
                    //良品以外は前日をコピー
                    {
                      if (ymdInfoYesterday.infos?.stock?.stockNormal1BL) {
                        ymdInfo.infos.stock.stockNormal1BL = ymdInfoYesterday.infos?.stock?.stockNormal1BL;
                      }
                      if (ymdInfoYesterday.infos?.stock?.stockNormal2BL) {
                        ymdInfo.infos.stock.stockNormal2BL = ymdInfoYesterday.infos?.stock?.stockNormal2BL;
                      }
                      if (ymdInfoYesterday.infos?.stock?.stockNormal3BL) {
                        ymdInfo.infos.stock.stockNormal3BL = ymdInfoYesterday.infos?.stock?.stockNormal3BL;
                      }
                      if (ymdInfoYesterday.infos?.stock?.stockUseUpBL) {
                        ymdInfo.infos.stock.stockUseUpBL = ymdInfoYesterday.infos?.stock?.stockUseUpBL;
                      }
                      if (ymdInfoYesterday.infos?.stock?.stockReturnedBL) {
                        ymdInfo.infos.stock.stockReturnedBL = ymdInfoYesterday.infos?.stock?.stockReturnedBL;
                      }
                    }
                    ymdInfo.infos.stock.stateNormal = "calc";
                  }
                }

                break;
              case "today":
                //(計算)前日在庫+入庫-出庫-当日振替+転送予定
                //良品
                {
                  const warenousingBL = getWarehousingBL(ymdInfo?.infos?.warehousing, blIrisu);
                  let stockNormalBL_today = 
                    calcUtil.plus(
                      calcUtil.minus(
                        calcUtil.minus(
                          calcUtil.minus(
                            calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormalBL, warenousingBL), 
                          getShippingBL1(ymdInfo?.infos?.shipping, blIrisu)),
                        ymdInfo?.infos?.stock?.stockUseUpBL),
                      ymdInfo?.infos?.stock?.stockReturnedBL),
                    getTransferBL(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_today == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_today = null;
                  }

                  if (ymdInfo.infos.stock?.todayType !== 'now') {
                    ymdInfo.infos.stock.stockNormalBL = stockNormalBL_today;
                  }
                }
                //一般
                {
                  const warenousingBL = getWarehousingBL1(ymdInfo?.infos?.warehousing, blIrisu);
                  let stockNormalBL_today = 
                    calcUtil.plus(
                      calcUtil.minus(
                        calcUtil.minus(
                          calcUtil.minus(
                            calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormal1BL, warenousingBL), 
                          getShippingBL1(ymdInfo?.infos?.shipping, blIrisu)),
                        ymdInfo?.infos?.stock?.stockUseUpBL),
                      ymdInfo?.infos?.stock?.stockReturnedBL),
                    getTransferBL1(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_today == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_today = null;
                  }
                  ymdInfo.infos.stock.stockNormal1BL = stockNormalBL_today;
                }
                //特売
                {
                  const warenousingBL = getWarehousingBL2(ymdInfo?.infos?.warehousing, blIrisu);
                  let stockNormalBL_today = 
                    calcUtil.plus(
                      calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormal2BL, warenousingBL), 
                    getTransferBL2(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_today == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_today = null;
                  }
                  ymdInfo.infos.stock.stockNormal2BL = stockNormalBL_today;
                }
                //予約
                {
                  const warenousingBL = getWarehousingBL3(ymdInfo?.infos?.warehousing, blIrisu);
                  let stockNormalBL_today = 
                    calcUtil.plus(
                      calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormal3BL, warenousingBL), 
                    getTransferBL3(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_today == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_today = null;
                  }
                  ymdInfo.infos.stock.stockNormal3BL = stockNormalBL_today;
                }
                ymdInfo.infos.stock.stateNormal = "calc";
                if(editRowKeysSet) {
                  editRowKeysSet.add('stockNormal');  //再描写対象として記録
                }
                break;
              case "future":
                //(計算)前日在庫+入庫-出庫-受注済+転送予定
                //良品
                {
                  let stockNormalBL_future = 
                    calcUtil.plus(
                      calcUtil.minus(
                        calcUtil.minus(
                          calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormalBL, getWarehousingBL(ymdInfo?.infos?.warehousing, blIrisu)), 
                        getShippingBL(ymdInfo?.infos?.shipping, blIrisu)), 
                      getNumBLfromPS(ymdInfo?.infos?.storeOrder?.storeOrderPS, blIrisu)), 
                    getTransferBL(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_future == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_future = null;
                  }
                  ymdInfo.infos.stock.stockNormalBL = stockNormalBL_future;
                }
                //一般
                {
                  let stockNormalBL_future = 
                    calcUtil.plus(
                      calcUtil.minus(
                        calcUtil.minus(
                          calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormal1BL, getWarehousingBL1(ymdInfo?.infos?.warehousing, blIrisu)), 
                        getShippingBL(ymdInfo?.infos?.shipping, blIrisu)), 
                      getNumBLfromPS(ymdInfo?.infos?.storeOrder?.storeOrderPS, blIrisu)), 
                    getTransferBL1(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_future == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_future = null;
                  }
                  ymdInfo.infos.stock.stockNormal1BL = stockNormalBL_future;
                }
                //特売
                {
                  let stockNormalBL_future = 
                    calcUtil.plus(
                      calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormal2BL, getWarehousingBL2(ymdInfo?.infos?.warehousing, blIrisu)), 
                      getTransferBL2(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_future == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_future = null;
                  }
                  ymdInfo.infos.stock.stockNormal2BL = stockNormalBL_future;
                }
                //予約
                {
                  let stockNormalBL_future = 
                    calcUtil.plus(
                      calcUtil.plus(ymdInfoYesterday?.infos?.stock?.stockNormal3BL, getWarehousingBL3(ymdInfo?.infos?.warehousing, blIrisu)), 
                      getTransferBL3(ymdInfo?.infos?.transferIn, ymdInfo?.infos?.transferOut));
                  //子センターで在庫0ならば空白にする
                  if(stockNormalBL_future == 0 && ymdInfo?.infos?.transferIn?.koShippingBL) {
                    stockNormalBL_future = null;
                  }
                  ymdInfo.infos.stock.stockNormal3BL = stockNormalBL_future;
                }
                ymdInfo.infos.stock.stateNormal = "calc";
                if(editRowKeysSet) {
                  editRowKeysSet.add('stockNormal');  //再描写対象として記録
                  editRowKeysSet.add('stockNormal1');  //再描写対象として記録
                  editRowKeysSet.add('stockNormal2');  //再描写対象として記録
                  editRowKeysSet.add('stockNormal3');  //再描写対象として記録
                }
                break;
              default:
                break;
            }
          });
        }
        break;
      case 'stockNormal1':
      case 'stockNormal2':
      case 'stockNormal3':
        // 'stockNormal':でまとめて計算している
            break;
      // case 'stockUseUp':
      //   break;
      // case 'stockReturned':
      //   break;
      // case "similarItem_warehousing":
      //   break;
      // case "similarItem_shipping":
      //   break;
      case "similarItem_stockNormal":
        targetYmds.forEach((ymd, index) => {
          const ymdIndex = index + fromIndex;
          const ymdInfo = dataGroup.ymdInfos[ymdIndex];
          const ymdInfoYesterday = dataGroup.ymdInfos[ymd.yesterdayIndex];
          switch (ymd.type) {
            case "past":
              break;
            case "today":
              //(計算)前日在庫+入庫-出庫-当日振替
              let stockNormalBL_today = 
                calcUtil.minus(
                  calcUtil.plus(ymdInfoYesterday?.infos?.similarItem_stock?.stockNormalBL, getWarehousingBL(ymdInfo?.infos?.similarItem_warehousing, blIrisu)), 
                getShippingBL(ymdInfo?.infos?.similarItem_shipping, blIrisu));
              ymdInfo.infos.similarItem_stock.stockNormalBL = stockNormalBL_today;
              ymdInfo.infos.similarItem_stock.stateNormal = "calc";
              if(editRowKeysSet) editRowKeysSet.add(rowKey);  //再描写対象として記録
              break;
            case "future":
              //(計算)前日在庫+入庫-出庫-受注済+転送予定
              let stockNormalBL_future = 
                    calcUtil.minus(
                      calcUtil.plus(ymdInfoYesterday?.infos?.similarItem_stock?.stockNormalBL, getWarehousingBL(ymdInfo?.infos?.similarItem_warehousing, blIrisu)), 
                    getShippingBL(ymdInfo?.infos?.similarItem_shipping, blIrisu));
              ymdInfo.infos.similarItem_stock.stockNormalBL = stockNormalBL_future;
              ymdInfo.infos.similarItem_stock.stateNormal = "calc";
              if(editRowKeysSet) editRowKeysSet.add(rowKey);  //再描写対象として記録
              break;
            default:
              break;
          }
        });
        break;
      default:
        break;
    }

  });


  if(!notCalcPast) {
    dataGroup.freshnessAlert = false;
    dataGroup.freshnessAlertBL = null;
    if(dataGroup.centerItem.bestBefore < 9999 && dataGroup.centerItem.zaikoKigenOfAlert && dataGroup.centerItem.zaikoKigen) {
      //在庫賞味期限
      const alertDate = dataGroup.centerItem.zaikoKigenOfAlert;
      if(dataGroup.centerItem.zaikoKigen <= alertDate) {
        dataGroup.freshnessAlert = true;
        //BL数計算
        let ymd_today = ymds.find(ymd => ymd.type == 'today');
        if(ymd_today) {
          const ymdIndex = ymd_today.index;
          //現在庫数
          // let stockNormalBL = dataGroup.ymdInfos[ymdIndex]?.infos?.stock?.stockNormalBL;
          //前日庫数
          let stockNormalBL = dataGroup.ymdInfos[ymdIndex-1]?.infos?.stock?.stockNormalBL;
          //許容期限アラート在庫期限(YY/MM/DD)
          let zaikoKigenOfAlert = dataGroup.centerItem?.zaikoKigenOfAlert;
          //賞味期限別入庫一覧
          let infos = dataGroup.ymdInfos
                            // .filter(ymd => !ymd.week && (ymd.type == "today" || ymd.type == "past" || ymd.type == "past_hidden"))
                            .filter(ymd => !ymd.week && (ymd.type == "past" || ymd.type == "past_hidden"))
                            .sort((a, b) => a.ymdID < b.ymdID ? 1 : a.ymdID > b.ymdID ? -1 : 0) //大きい順
                            .map(ymd => ymd.infos?.bestBefore)
                            .filter(info => info?.bestBeforePS) //入荷ある奴だけ
                            ;

          if(stockNormalBL && zaikoKigenOfAlert && infos?.length) {
            const blIrisu = dataGroup.centerItem.blIrisu;
            let freshnessAlertBL = 0;
            infos.forEach(info => {
              if(stockNormalBL>0) {
                let bestBeforeBL = Math.min(stockNormalBL, getBestBeforeBL(info, blIrisu));
                if(!(!info.bestBefore) && info.bestBefore <= zaikoKigenOfAlert) {
                  //条件を満たすと在庫すべてをアラートにする
                  freshnessAlertBL += bestBeforeBL;
                }
                stockNormalBL -= bestBeforeBL;  
              }
            });
            dataGroup.freshnessAlertBL = freshnessAlertBL;
          }
        }
      }
    }
  }

  dataGroup.calced3 = true;
  return dataGroup;
}

//数値のパース(数値が文字列で返ってくる)
export const parseDataYMD = (datas:YMD[]): YMD[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);
    if(typeof data.week_ymdfrom === 'string') data.week_ymdfrom = parseInt(data.week_ymdfrom);
    if(typeof data.week_ymdto === 'string') data.week_ymdto = parseInt(data.week_ymdto);
  });
  return datas;  
}
export const parseDataCenterItem = (datas:CenterItem[]): CenterItem[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    if(typeof data.lot === 'string') data.lot = parseInt(data.lot);
    if(typeof data.csIrisu_lot === 'string') data.csIrisu_lot = parseInt(data.csIrisu_lot);
    if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
    if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);
    if(typeof data.capacityCS === 'string') data.capacityCS = parseFloat(data.capacityCS);
    if(typeof data.bestBefore === 'string') data.bestBefore = parseFloat(data.bestBefore);
    if(typeof data.allowablePeriod === 'string') data.allowablePeriod = parseFloat(data.allowablePeriod);
    if(typeof data.zaikoKigenUketsukeYmd === 'string') data.zaikoKigenUketsukeYmd = parseInt(data.zaikoKigenUketsukeYmd);
    if(typeof data.freshnessDaysOfAlert === 'string') data.freshnessDaysOfAlert = parseInt(data.freshnessDaysOfAlert);
  });
  return datas;
}
export const parseItemTagData = (datas:ItemTagData[]): ItemTagData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    data.itemTag = itemTags.find(itemTag => itemTag.code == data.tagCD);  //itemTagをセットする

    if(typeof data.termStartDate === 'string') data.termStartDate = parseInt(data.termStartDate);
    if(typeof data.termEndDate === 'string') data.termEndDate = parseInt(data.termEndDate);
  });
  return datas;
}
export const parseItemCommonData = (datas:ItemCommonData[]): ItemCommonData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.loadedQty === 'string') data.loadedQty = parseInt(data.loadedQty);
  });
  return datas;
}
export const parseDisableDateData = (datas:DisableDateData[]): DisableDateData[] => {
  datas = [...datas];
  datas.forEach((data) => {
  });
  return datas;
}
export const parseMakerTagData = (datas:MakerTagData[]): MakerTagData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.leadTimeDays === 'string') data.leadTimeDays = parseInt(data.leadTimeDays);
    // if(typeof data.minQtyCs === 'string') data.minQtyCs = parseInt(data.minQtyCs);
    // if(typeof data.maxQtyCs === 'string') data.maxQtyCs = parseInt(data.maxQtyCs);
    // if(typeof data.autoOrderExecDays === 'string') data.autoOrderExecDays = parseInt(data.autoOrderExecDays);
    // if(typeof data.autoOrderDays === 'string') data.autoOrderDays = parseInt(data.autoOrderDays);
  });
  return datas;
}
export const parseMakerLimitData = (datas:MakerLimitData[]): MakerLimitData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.stockLimitDate === 'string') data.stockLimitDate = parseInt(data.stockLimitDate);
    if(typeof data.stockLimitBL === 'string') data.stockLimitBL = parseInt(data.stockLimitBL);
    if(typeof data.warehousingBL_past === 'string') data.warehousingBL_past = parseInt(data.warehousingBL_past);
  
  });
  return datas;
}
export const parseDataInfoDataStoreSales = (datas:InfoDataStoreSales[]): InfoDataStoreSales[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);

    if(typeof data.storeSalesBL === 'string') data.storeSalesBL = parseFloat(data.storeSalesBL);
    if(data.storeSalesBL == 0) data.storeSalesBL = null;
  });
  return datas;
}
export const parseDataInfoDataTransfer = (datas:InfoDataTransfer[]): InfoDataTransfer[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);

    if(typeof data.transferBL_result === 'string') data.transferBL_result = parseFloat(data.transferBL_result);
    if(data.transferBL_result == 0) data.transferBL_result = null;

    if(typeof data.transferBL_result1 === 'string') data.transferBL_result1 = parseFloat(data.transferBL_result1);
    if(data.transferBL_result1 == 0) data.transferBL_result1 = null;

    if(typeof data.transferBL_result2 === 'string') data.transferBL_result2 = parseFloat(data.transferBL_result2);
    if(data.transferBL_result2 == 0) data.transferBL_result2 = null;

    if(typeof data.transferBL_result3 === 'string') data.transferBL_result3 = parseFloat(data.transferBL_result3);
    if(data.transferBL_result3 == 0) data.transferBL_result3 = null;

    if(typeof data.transferBL_edit === 'string') data.transferBL_edit = parseFloat(data.transferBL_edit);
    if(data.transferBL_edit == 0) data.transferBL_edit = null;
  });
  return datas;
}
export const parseDataInfoDataWarehousing = (datas:InfoDataWarehousing[]): InfoDataWarehousing[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);

    if(typeof data.warehousingBL_edit === 'string') data.warehousingBL_edit = parseFloat(data.warehousingBL_edit);
    if(data.warehousingBL_edit == 0) data.warehousingBL_edit = null;

    if(typeof data.warehousingBL_if === 'string') data.warehousingBL_if = parseFloat(data.warehousingBL_if);
    if(data.warehousingBL_if == 0) data.warehousingBL_if = null;

    // if(typeof data.warehousingBL_result === 'string') data.warehousingBL_result = parseFloat(data.warehousingBL_result);
    // if(data.warehousingBL_result == 0) data.warehousingBL_result = null;
    if(typeof data.warehousingPS_result === 'string') data.warehousingPS_result = parseFloat(data.warehousingPS_result);
    if(data.warehousingPS_result == 0) data.warehousingPS_result = null;

    if(typeof data.warehousingPS_result1 === 'string') data.warehousingPS_result1 = parseFloat(data.warehousingPS_result1);
    if(data.warehousingPS_result1 == 0) data.warehousingPS_result1 = null;

    if(typeof data.warehousingPS_result2 === 'string') data.warehousingPS_result2 = parseFloat(data.warehousingPS_result2);
    if(data.warehousingPS_result2 == 0) data.warehousingPS_result2 = null;

    if(typeof data.warehousingPS_result3 === 'string') data.warehousingPS_result3 = parseFloat(data.warehousingPS_result3);
    if(data.warehousingPS_result3 == 0) data.warehousingPS_result3 = null;

    if(typeof data.warehousingBL_plan === 'string') data.warehousingBL_plan = parseFloat(data.warehousingBL_plan);
  });
  return datas;
}
export const parseDataInfoDataOrder = (datas:InfoDataOrder[]): InfoDataOrder[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);

    data.orders?.forEach((data) => {
      if(typeof data.orderBL === 'string') data.orderBL = parseFloat(data.orderBL);
    });

  });
  return datas;
}
export const parseDataInfoDataBestBefore = (datas:InfoDataBestBefore[]): InfoDataBestBefore[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);
    if(typeof data.bestBeforePS === 'string') data.bestBeforePS = parseInt(data.bestBeforePS);
  });
  return datas;
}
export const parseDataInfoDataStoreOrder = (datas:InfoDataStoreOrder[]): InfoDataStoreOrder[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);

    if(typeof data.storeOrderPS === 'string') data.storeOrderPS = parseFloat(data.storeOrderPS);
    if(data.storeOrderPS == 0) data.storeOrderPS = null;
  });
  return datas;
}
export const parseDataInfoDataShipping = (datas:InfoDataShipping[]): InfoDataShipping[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);

    if(typeof data.shippingBL_edit === 'string') data.shippingBL_edit = parseFloat(data.shippingBL_edit);
    // if(data.shippingBL_edit == 0) data.shippingBL_edit = null;

    // if(typeof data.shippingBL_result === 'string') data.shippingBL_result = parseFloat(data.shippingBL_result);
    // if(data.shippingBL_result == 0) data.shippingBL_result = null;
    if(typeof data.shippingPS_result === 'string') data.shippingPS_result = parseFloat(data.shippingPS_result);
    if(data.shippingPS_result == 0) data.shippingPS_result = null;

    if(typeof data.shippingPS_result1 === 'string') data.shippingPS_result1 = parseFloat(data.shippingPS_result1);
    if(data.shippingPS_result1 == 0) data.shippingPS_result1 = null;

    if(typeof data.shippingPS_original === 'string') data.shippingPS_original = parseFloat(data.shippingPS_original);
    if(data.shippingPS_original == 0) data.shippingPS_original = null;

    if(typeof data.shippingBL_zantei === 'string') data.shippingBL_zantei = parseFloat(data.shippingBL_zantei);
    if(data.shippingBL_zantei == 0) data.shippingBL_zantei = null;
  });
  return datas;
}
export const parseDataInfoDataShippingGroup1 = (datas:InfoDataShippingGroup1[]): InfoDataShippingGroup1[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);

    if(typeof data.shippingPS_result === 'string') data.shippingPS_result = parseFloat(data.shippingPS_result);
    if(data.shippingPS_result == 0) data.shippingPS_result = null;
  });
  return datas;
}
export const parseDataInfoDataShippingTokubai = (datas:InfoDataShippingTokubai[]): InfoDataShippingTokubai[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID_f === 'string') data.ymdID_f = parseInt(data.ymdID_f);
    if(typeof data.ymdID_t === 'string') data.ymdID_t = parseInt(data.ymdID_t);

    if(typeof data.shippingPS_result === 'string') data.shippingPS_result = parseFloat(data.shippingPS_result);
    if(data.shippingPS_result == 0) data.shippingPS_result = null;
  });
  return datas;
}
export const parseDataInfoDataStock = (datas:InfoDataStock[]): InfoDataStock[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);
  
    if(typeof data.stockNormalBL === 'string') data.stockNormalBL = parseFloat(data.stockNormalBL);
    if(data.stockNormalBL == 0) data.stockNormalBL = null;

    if(typeof data.stockUseUpBL === 'string') data.stockUseUpBL = parseFloat(data.stockUseUpBL);
    if(data.stockUseUpBL == 0) data.stockUseUpBL = null;

    if(typeof data.stockReturnedBL === 'string') data.stockReturnedBL = parseFloat(data.stockReturnedBL);
    if(data.stockReturnedBL == 0) data.stockReturnedBL = null;

  });
  return datas;
}
export const weathers = [
  { code: 0, descriptionEn: 'Clear sky', icon: '🌞', descriptionJa: '晴れ',  },
  { code: 1, descriptionEn: 'Mainly clear', icon: '🌞', descriptionJa: '晴れ',  },
  { code: 2, descriptionEn: 'partly cloudy', icon: '🌞', descriptionJa: '晴れ',  },
  { code: 3, descriptionEn: 'overcast', icon: '⛅', descriptionJa: '曇り',  },
  { code: 45, descriptionEn: 'Fog', icon: '🌫', descriptionJa: '霧',  },
  { code: 48, descriptionEn: 'depositing rime fog', icon: '🌫', descriptionJa: '霧',  },
  { code: 51, descriptionEn: 'Drizzle: Light', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 53, descriptionEn: 'Drizzle: moderate', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 55, descriptionEn: 'Drizzle: dense intensity', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 56, descriptionEn: 'Freezing Drizzle: Light', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 57, descriptionEn: 'Freezing Drizzle: dense intensity', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 61, descriptionEn: 'Rain: Slight', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 63, descriptionEn: 'Rain: moderate', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 65, descriptionEn: 'Rain: heavy intensity', icon: '⛈️', descriptionJa: '大雨', alert: true,  },
  { code: 66, descriptionEn: 'Freezing Rain: Light', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 67, descriptionEn: 'Freezing Rain: heavy intensity', icon: '⛈️', descriptionJa: '大雨', alert: true,  },
  { code: 71, descriptionEn: 'Snow fall: Slight', icon: '❄', descriptionJa: '小雪',  },
  { code: 73, descriptionEn: 'Snow fall:  moderate', icon: '❄', descriptionJa: '小雪', alert: true,  },
  { code: 75, descriptionEn: 'Snow fall: heavy intensity', icon: '⛄', descriptionJa: '大雪', alert: true,  },
  { code: 77, descriptionEn: 'Snow grains', icon: '❄', descriptionJa: '小雪',  },
  { code: 80, descriptionEn: 'Rain showers: Slight', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 81, descriptionEn: 'Rain showers: moderate', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 82, descriptionEn: 'Rain showers: violent', icon: '⛈️', descriptionJa: '大雨', alert: true,  },
  { code: 85, descriptionEn: 'Snow showers slight', icon: '❄', descriptionJa: '小雪',  },
  { code: 86, descriptionEn: 'Snow showers heavy', icon: '⛄', descriptionJa: '大雪', alert: true,  },
  { code: 95, descriptionEn: 'Thunderstorm: Slight or moderate', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 96, descriptionEn: 'Thunderstorm with slight', icon: '🌧️', descriptionJa: '小雨',  },
  { code: 99, descriptionEn: 'Thunderstorm with heavy hail', icon: '⚡', descriptionJa: '雷雨', alert: true,  },
  ];

//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });
const makeWeatherTable = (data?: OpenMeteoDaily): string => {
  const icon = data?.weathericon ?? '　';
  const tempMax = data?.temperature_2m_max !== undefined ? formatterN0.format(data?.temperature_2m_max) : '　';
  const tempMin = data?.temperature_2m_min !== undefined ? formatterN0.format(data?.temperature_2m_min) : '　';
  return `
      <div style="width: 100%;display: flex;flex-wrap: wrap;">
        <div style="width: 50%;box-sizing: border-box;padding: 2px 0 0 0 !important;font-size: 19px;text-align: right;">${icon}</div>
        <div style="width: 50%;box-sizing: border-box;padding: 0 2px 0 0 !important;font-size: 10px;text-align: right;">${tempMax}<br/>${tempMin}</div>
      </div>
    `;
}
export const blankWeatherTable = (): string => {
  return makeWeatherTable();
}
export const parseDataInfoDataWeather = (datas: OpenMeteoDaily[]): OpenMeteoDaily[] => {

  datas = [...datas];
  datas.forEach((data) => {
    if (typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);
    if (typeof data.weathercode === 'string') data.weathercode = parseInt(data.weathercode);
    if (typeof data.temperature_2m_max === 'string') data.temperature_2m_max = parseFloat(data.temperature_2m_max);
    if (typeof data.temperature_2m_min === 'string') data.temperature_2m_min = parseFloat(data.temperature_2m_min);
    if (typeof data.rain_sum === 'string') data.rain_sum = parseFloat(data.rain_sum);

    const weather = weathers.find(w => w.code == data.weathercode);
    data.weathericon = weather?.icon ?? '' + (data.weathercode ?? '');
    const ss:string[] = [];
    if(data.weathericon) {
      ss.push(data.weathericon);
    }
    if(data.temperature_2m_min && data.temperature_2m_max) {
      ss.push(formatterN0.format(data.temperature_2m_min) + '/' + formatterN0.format(data.temperature_2m_max) + '℃');
    }
    if(data.rain_sum || data.rain_sum === 0) {
      ss.push(formatterN0.format(data.rain_sum) + '㎜');
    }
    data.weather = ss.join(' ');
    data.innerHTML = makeWeatherTable(data);
    data.state = weather?.alert ? 'weather_alert' : null;
  });
  return datas;
}
export const parseDataCommentData = (datas:CommentData[]): CommentData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if(typeof data.ymdID === 'string') data.ymdID = parseInt(data.ymdID);
  });
  return datas;
}

export const rowToIdx = (row:number, pageInfo:PageInfo, fixedRowsTop:number):number => {
  if(row < fixedRowsTop) {  //Total
    return row;
  }
  if(!pageInfo) {
    return row;
  }
  return row - fixedRowsTop + pageInfo.rowInfoIdxFrom;
}

export const idxToRow = (idx:number, pageInfo:PageInfo, fixedRowsTop:number):number => {
  if(idx < fixedRowsTop) {  //Total
    return idx;
  }
  if(!pageInfo) {
    return idx;
  }
  return idx + fixedRowsTop - pageInfo.rowInfoIdxFrom;
}

export type OpenMeteoDaily = {
  ymdID?: number | null, //年月日
  centerCD?: string | null, //倉庫

  weathercode?: number,
  weathericon?: string,
  weather?: string,
  innerHTML?: string,
  temperature_2m_max?: number,
  temperature_2m_min?: number,
  rain_sum?: number,
  state?: '' | 'weather_alert',
}

export const findDataGroupIndex = (itemCD:string, centerCD:string, dataGroups:RowDataGroup[]): number => {
  if(!itemCD || !centerCD) {
    return null;
  }
  return dataGroups.findIndex(dataGroup => true
    && dataGroup?.centerItem?.itemCD == itemCD
    && dataGroup?.centerItem?.centerCD == centerCD
  );
}
