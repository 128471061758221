import { maxWeeksFuture, maxWeeksPastDaily, maxWeeksPastWeekly } from "@/store/mainpage/mainPageCommon";
import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'attribute1Label', 'attribute1Value',
  'attribute2Label', 'attribute2Value',
  'itemTag', 
];
//行ヘッダー列
export const rowHeaderColKeys = [
  'rowHeader', 
];
//期間列
export const termColKeys:string[] = [...Array(maxWeeksPastWeekly+(maxWeeksPastWeekly+maxWeeksFuture)*7)].map((_, index) => `T${`000${index+1}`.slice(-3)}`);
// export const termColKeys = [
//   "T001", "T002", "T003", "T004", "T005", "T006", "T007", "T008", "T009", "T010",
//   "T011", "T012", "T013", "T014", "T015", "T016", "T017", "T018", "T019", "T020",
//   "T021", "T022", "T023", "T024", "T025", "T026", "T027", "T028", "T029", "T030",
//   "T031", "T032", "T033", "T034", "T035", "T036", "T037", "T038", "T039", "T040",
//   "T041", "T042", "T043", "T044", "T045", "T046", "T047", "T048", "T049", "T050",
//   "T051", "T052", "T053", "T054", "T055", "T056", "T057", "T058", "T059", "T060",
// ];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
];

export const rowKeys = [
  "weather",  //天気
  "storeSales",   //店頭売上",
  "transfer",     //転送予定",
  "warehousing",  //入庫",
  "warehousing_last",  //前年入庫",
  "bestBefore",   //賞味期限",
  "storeOrder",   //受注",
  "shipping_original",     //出庫(移管含まず)",
  "shipping",     //出庫",
  "shipping_storeOrder",     //出庫(先付含)",
  "shipping_last",     //前年出庫",
  // "shipping_group1",  //ドリルダウン
  "stockNormal",  //在庫（良品）",
  "stockNormal1",  //在庫（一般）",
  "stockNormal2",  //在庫（特売）",
  "stockNormal3",  //在庫（予約）",
  "stockUseUp",   //在庫（出切）",
  "stockReturned",  //在庫（返品）",

  "similarItem_warehousing",  //: "[類似商品]入庫",
  "similarItem_shipping",     //"[類似商品]出庫",
  "similarItem_stockNormal",  //"[類似商品]在庫（良品）",
];
//行項目　発注不可時非表示
export const rowKeysUnOrderableHidden = [
  // "shipping_storeOrder",     //出庫(先付含)",
];
//行項目　部門設定在庫（良品）内訳非表示
export const rowKeysBumonHiddenZaikoNormal = [
  "stockNormal1",  //在庫（一般）",
  "stockNormal2",  //在庫（特売）",
  "stockNormal3",  //在庫（予約）",
];
//行項目　部門設定在庫（良品）内訳非表示
export const rowKeysBumonHiddenZaikoNormalYoyaku = [
  // "stockNormal1",  //在庫（一般）",
  "stockNormal2",  //在庫（特売）",
  // "stockNormal3",  //在庫（予約）",
];
//行項目　部門設定在庫（良品）内訳非表示
export const rowKeysBumonHiddenZaikoNormalTokubai = [
  // "stockNormal1",  //在庫（一般）",
  // "stockNormal2",  //在庫（特売）",
  "stockNormal3",  //在庫（予約）",
];
//行項目　部門設定店頭発注非表示
export const rowKeysBumonHiddenStoreOrder = [
  "storeSales",   //店頭売上",
];
//行項目　移管設定なし時非表示
export const rowKeysNotIkanHidden = [
  "shipping_original",   //出庫(先付含)",
];

//Excelデータシート用
export const headerColKeys_ExcelDataSheet = [
  'no',

  'centerCD', 
  'centerNM', 
  'itemCD',
  'itemNM',
];
export const headerSubColKeys_ExcelDataSheet = [
  'area',
  'pref',
  'janCD',
  'makerCD',
  'makerNM',
  'categoryCD',
  'categoryNM',
  'blIrisu',
  'csIrisu',
  'lot',
  // 'csIrisu_lot',
  'capacity',
  'bestBefore',
  'teika',
  'tatene',
  'tanaban',
  "tokuisakiCD",
  "tokuisakiNM",
  "tokuisakiItemCD",
  "teibanTokuisakiCD",
  "teibanTokuisakiNM",
  "teibanTokuisakiItemCD",
  "oyaCenter",
  "koCenter",
];
export const rowKeys_ExcelDataSheet = [
  "storeSales",   //店頭売上",
  "transfer",     //転送予定",
  "warehousing",  //入庫",
  "bestBefore",   //賞味期限",
  "storeOrder",   //受注",
  // "shipping_original",     //出庫(移管含まず)",
  "shipping",     //出庫",
  // "shipping_storeOrder",     //出庫(先付含)",
  // "shipping_group1",     //出庫",
  "stockNormal",  //在庫（良品）",
  "stockNormal1",  //在庫（一般）",
  "stockNormal2",  //在庫（特売）",
  "stockNormal3",  //在庫（予約）",
  "stockUseUp",   //在庫（出切）",
  "stockReturned",  //在庫（返品）",
  "weather",  //天気

  // "similarItem_warehousing",  //: "[類似商品]入庫",
  // "similarItem_shipping",     //"[類似商品]出庫",
  // "similarItem_stockNormal",  //"[類似商品]在庫（良品）",
];

export const headersCol = [
  {
    no : "No.",
    attribute1Label: "商品/倉庫-属性1", attribute1Value: "商品/倉庫-属性1", 
    attribute2Label: "商品/倉庫-属性2", attribute2Value: "商品/倉庫-属性2", 
    itemTag: "商品発注",
    rowHeader : "日付", 
    // T001: "%YMD001%", T002: "%YMD002%", T003: "%YMD003%", T004: "%YMD004%", T005: "%YMD005%", T006: "%YMD006%", T007: "%YMD007%", T008: "%YMD008%", T009: "%YMD009%", T010: "%YMD010%",
    // T011: "%YMD011%", T012: "%YMD012%", T013: "%YMD013%", T014: "%YMD014%", T015: "%YMD015%", T016: "%YMD016%", T017: "%YMD017%", T018: "%YMD018%", T019: "%YMD019%", T020: "%YMD020%",
    // T021: "%YMD021%", T022: "%YMD022%", T023: "%YMD023%", T024: "%YMD024%", T025: "%YMD025%", T026: "%YMD026%", T027: "%YMD027%", T028: "%YMD028%", T029: "%YMD029%", T030: "%YMD030%",
    // T031: "%YMD031%", T032: "%YMD032%", T033: "%YMD033%", T034: "%YMD034%", T035: "%YMD035%", T036: "%YMD036%", T037: "%YMD037%", T038: "%YMD038%", T039: "%YMD039%", T040: "%YMD040%",
    // T041: "%YMD041%", T042: "%YMD042%", T043: "%YMD043%", T044: "%YMD044%", T045: "%YMD045%", T046: "%YMD046%", T047: "%YMD047%", T048: "%YMD048%", T049: "%YMD049%", T050: "%YMD050%",
    // T051: "%YMD051%", T052: "%YMD052%", T053: "%YMD053%", T054: "%YMD054%", T055: "%YMD055%", T056: "%YMD056%", T057: "%YMD057%", T058: "%YMD058%", T059: "%YMD059%", T060: "%YMD060%",

    //Excelデータシート用
    centerCD : "倉庫",
    centerNM : "倉庫",
    itemCD : "商品",
    itemNM : "商品",

    area : "倉庫属性",
    pref : "倉庫属性",
       
    janCD : "商品属性",
    makerCD : "商品属性",
    makerNM : "商品属性",
    categoryCD : "商品属性",
    categoryNM : "商品属性",
    blIrisu : "商品属性",
    csIrisu : "商品属性",
    lot : "商品属性",
    capacity : "商品属性",
    bestBefore : "商品属性",
    teika : "商品属性",
    tatene : "商品属性",
    tanaban : "倉庫属性",
    tokuisakiCD : "得意先",
    tokuisakiNM : "得意先",
    tokuisakiItemCD : "得意先",
    teibanTokuisakiCD : "得意先",
    teibanTokuisakiNM : "得意先",
    teibanTokuisakiItemCD : "得意先",
    oyaCenter : "親子センター",
    koCenter : "親子センター",
  },
  {
    no : "",
    attribute1Label: "項目名", attribute1Value: "値", 
    attribute2Label: "項目名", attribute2Value: "値", 
    itemTag: "詳細設定",
    rowHeader : "項目", 
    // T001: "%WKD001%", T002: "%WKD002%", T003: "%WKD003%", T004: "%WKD004%", T005: "%WKD005%", T006: "%WKD006%", T007: "%WKD007%", T008: "%WKD008%", T009: "%WKD009%", T010: "%WKD010%",
    // T011: "%WKD011%", T012: "%WKD012%", T013: "%WKD013%", T014: "%WKD014%", T015: "%WKD015%", T016: "%WKD016%", T017: "%WKD017%", T018: "%WKD018%", T019: "%WKD019%", T020: "%WKD020%",
    // T021: "%WKD021%", T022: "%WKD022%", T023: "%WKD023%", T024: "%WKD024%", T025: "%WKD025%", T026: "%WKD026%", T027: "%WKD027%", T028: "%WKD028%", T029: "%WKD029%", T030: "%WKD030%",
    // T031: "%WKD031%", T032: "%WKD032%", T033: "%WKD033%", T034: "%WKD034%", T035: "%WKD035%", T036: "%WKD036%", T037: "%WKD037%", T038: "%WKD038%", T039: "%WKD039%", T040: "%WKD040%",
    // T041: "%WKD041%", T042: "%WKD042%", T043: "%WKD043%", T044: "%WKD044%", T045: "%WKD045%", T046: "%WKD046%", T047: "%WKD047%", T048: "%WKD048%", T049: "%WKD049%", T050: "%WKD050%",
    // T051: "%WKD051%", T052: "%WKD052%", T053: "%WKD053%", T054: "%WKD054%", T055: "%WKD055%", T056: "%WKD056%", T057: "%WKD057%", T058: "%WKD058%", T059: "%WKD059%", T060: "%WKD060%",

    //Excelデータシート用
    centerCD : "倉庫CD",
    centerNM : "倉庫名",
    itemCD : "商品CD",
    itemNM : "商品名",

    area : "地域",
    pref : "都道府県",
   
    janCD : "JAN",
    makerCD : "ﾒｰｶｰCD",
    makerNM : "ﾒｰｶｰ名",
    categoryCD : "ｶﾃｺﾞﾘCD",
    categoryNM : "ｶﾃｺﾞﾘ名",
    blIrisu : "BL入数",
    csIrisu : "CS入数",
    lot : "発注単位",
    capacity : "容量",
    bestBefore : "賞味期間",
    teika : "定価",
    tatene : "建値",
    tanaban : "棚番",
    tokuisakiCD : "得意先CD",
    tokuisakiNM : "得意先名",
    tokuisakiItemCD : "得意先商品CD",
    teibanTokuisakiCD : "得意先CD",
    teibanTokuisakiNM : "得意先名",
    teibanTokuisakiItemCD : "得意先商品CD",
    oyaCenter : "親センター",
    koCenter : "子センター",
  },
];
[...Array(maxWeeksPastWeekly+(maxWeeksPastWeekly+maxWeeksFuture)*7)].forEach((_, index) => {
  const nnn = `000${index+1}`.slice(-3);
  const key = `T${nnn}`;
  headersCol[0][key] = `%YMD${nnn}%`;
  headersCol[1][key] = `%WKD${nnn}%`;
});

export const headersRowGeneralTeibal = 
  {
  storeSales: "店頭売上",
  transfer: "転送[一般]",
  warehousing: "入庫[一般]",
  warehousing_last: "前年入庫[一般]",
  bestBefore: "賞味期限",
  storeOrder: "受注[定番]",
  shipping_original: "出庫(移管除く)[定番]",
  shipping: "出庫[定番]",
  shipping_storeOrder: "出庫(先付含)[定番]",
  shipping_last: "前年出庫[定番]",
  stockNormal: "在庫[一般]",
  stockNormal1: "",
  stockNormal2: "",
  stockNormal3: "",
  stockUseUp: "在庫[出切]",
  stockReturned: "在庫[返品]",
  weather: "天気",

  similarItem_warehousing: "【類】入庫[一般]",
  similarItem_shipping: "【類】出庫[一般]",
  similarItem_stockNormal: "【類】在庫[一般]",
  }
;
export const headersRowGeneral = 
  {
  storeSales: "店頭売上",
  transfer: "転送[一般]",
  warehousing: "入庫[一般]",
  warehousing_last: "前年入庫[一般]",
  bestBefore: "賞味期限",
  storeOrder: "受注[一般]",
  shipping_original: "出庫(移管除く)[一般]",  //移管含まない出庫
  shipping: "出庫[一般]",
  shipping_storeOrder: "出庫(先付含)[一般]",
  shipping_last: "前年出庫[一般]",
  stockNormal: "在庫[一般]",
  stockNormal1: "",
  stockNormal2: "",
  stockNormal3: "",
  stockUseUp: "在庫[出切]",
  stockReturned: "在庫[返品]",
  weather: "天気",

  similarItem_warehousing: "【類】入庫[一般]",
  similarItem_shipping: "【類】出庫[一般]",
  similarItem_stockNormal: "【類】在庫[一般]",
  }
;
export const headersRowGeneralYoyaku = 
  {
  storeSales: "店頭売上",
  transfer: "転送[一般&予約]",
  warehousing: "入庫[一般&予約]",
  warehousing_last: "前年入庫[一般&予約]",
  bestBefore: "賞味期限",
  storeOrder: "受注[一般&予約]",
  shipping_original: "出庫(移管除く)[一般&予約]",
  shipping: "出庫[一般&予約]",
  shipping_storeOrder: "出庫(先付含)[一般&予約]",
  shipping_last: "前年出庫[一般&予約]",
  stockNormal: "在庫[一般&予約]",
  stockNormal1: "在庫[一般]",
  stockNormal2: "",
  stockNormal3: "在庫[予約]",
  stockUseUp: "在庫[出切]",
  stockReturned: "在庫[返品]",
  weather: "天気",

  similarItem_warehousing: "【類】入庫[一般&予約]",
  similarItem_shipping: "【類】出庫[一般&予約]",
  similarItem_stockNormal: "【類】在庫[一般&予約]",
  }
;
export const headersRowGeneralTokubai = 
  {
  storeSales: "店頭売上",
  transfer: "転送[一般&特売]",
  warehousing: "入庫[一般&特売]",
  warehousing_last: "前年入庫[一般&特売]",
  bestBefore: "賞味期限",
  storeOrder: "受注[一般&特売]",
  shipping_original: "出庫(移管除く)[一般&特売]",
  shipping: "出庫[一般&特売]",
  shipping_storeOrder: "出庫(先付含)[一般&特売]",
  shipping_last: "前年出庫[一般&特売]",
  stockNormal: "在庫[一般&特売]",
  stockNormal1: "在庫[一般]",
  stockNormal2: "在庫[特売]",
  stockNormal3: "",
  stockUseUp: "在庫[出切]",
  stockReturned: "在庫[返品]",
  weather: "天気",

  similarItem_warehousing: "【類】入庫[一般&特売]",
  similarItem_shipping: "【類】出庫[一般&特売]",
  similarItem_stockNormal: "【類】在庫[一般&特売]",
  }
;
export const headersRowAll = 
  {
  storeSales: "店頭売上",
  transfer: "転送",
  warehousing: "入庫",
  warehousing_last: "前年入庫",
  bestBefore: "賞味期限",
  storeOrder: "受注",
  shipping_original: "出庫(移管除く)",
  shipping: "出庫",
  shipping_storeOrder: "出庫(先付含)",
  shipping_last: "前年出庫",
  stockNormal: "在庫[良品]",
  stockNormal1: "在庫[一般]",
  stockNormal2: "在庫[特売]",
  stockNormal3: "在庫[予約]",
  stockUseUp: "在庫[出切]",
  stockReturned: "在庫[返品]",
  weather: "天気",

  similarItem_warehousing: "【類】入庫",
  similarItem_shipping: "【類】出庫",
  similarItem_stockNormal: "【類】在庫[良品]",
  }
;
export const headersRowFM = 
  {
  storeSales: "店頭売上",
  transfer: "転送(+子受注)",
  warehousing: "入庫",
  warehousing_last: "前年入庫",
  bestBefore: "賞味期限",
  storeOrder: "受注",
  shipping_original: "出庫(移管除く)",
  shipping: "出庫",
  shipping_storeOrder: "出庫(先付含)",
  shipping_last: "前年出庫",
  stockNormal: "在庫[一般]",
  stockNormal1: "",
  stockNormal2: "",
  stockNormal3: "",
  stockUseUp: "在庫[出切]",
  stockReturned: "在庫[返品]",
  weather: "天気",

  similarItem_warehousing: "【類】入庫",
  similarItem_shipping: "【類】出庫",
  similarItem_stockNormal: "【類】在庫[良品]",
  }
;
export const headersRowSEJ = 
  {
  storeSales: "店頭売上",
  transfer: "転送",
  warehousing: "入庫",
  warehousing_last: "前年入庫",
  bestBefore: "賞味期限",
  storeOrder: "受注",
  shipping_original: "出庫(移管除く)",
  shipping: "出庫",
  shipping_storeOrder: "出庫(先付含)",
  shipping_last: "前年出庫",
  stockNormal: "在庫[一般]",
  stockNormal1: "",
  stockNormal2: "",
  stockNormal3: "",
  stockUseUp: "在庫[出切]",
  stockReturned: "在庫[返品]",
  weather: "天気",

  similarItem_warehousing: "【類】入庫",
  similarItem_shipping: "【類】出庫",
  similarItem_stockNormal: "【類】在庫[良品]",
  }

;

export const selectableCol =
{
  no : false,
  attribute1Label: true, attribute1Value: false, 
  attribute2Label: true, attribute2Value: true, 
  itemTag: true,
  rowHeader : false, 
  // T001: false, T002: false, T003: false, T004: false, T005: false, T006: false, T007: false, T008: false, T009: false, T010: false,
  // T011: false, T012: false, T013: false, T014: false, T015: false, T016: false, T017: false, T018: false, T019: false, T020: false,
  // T021: false, T022: false, T023: false, T024: false, T025: false, T026: false, T027: false, T028: false, T029: false, T030: false,
  // T031: false, T032: false, T033: false, T034: false, T035: false, T036: false, T037: false, T038: false, T039: false, T040: false,
  // T041: false, T042: false, T043: false, T044: false, T045: false, T046: false, T047: false, T048: false, T049: false, T050: false,
  // T051: false, T052: false, T053: false, T054: false, T055: false, T056: false, T057: false, T058: false, T059: false, T060: false,

  //Excelデータシート用
  centerCD : false,
  centerNM : false,
  itemCD : false,
  itemNM : true,

  area : true,
  pref : true,
     
  janCD : true,
  makerCD : true,
  makerNM : true,
  categoryCD : true,
  categoryNM : true,
  blIrisu : true,
  csIrisu : true,
  lot : true,
  capacity : true,
  bestBefore : true,
  teika : true,
  tatene : true,
  tanaban : true,
  tokuisakiCD : true,
  tokuisakiNM : true,
  tokuisakiItemCD : true,
  teibanTokuisakiCD : true,
  teibanTokuisakiNM : true,
  teibanTokuisakiItemCD : true,
  oyaCenter : true,
  koCenter : true,
};
[...Array(maxWeeksPastWeekly+(maxWeeksPastWeekly+maxWeeksFuture)*7)].forEach((_, index) => {
  const nnn = `000${index+1}`.slice(-3);
  const key = `T${nnn}`;
  selectableCol[key] = false;
});

export const selectableRow =
{
  storeSales: true,   //"店頭売上",
  transfer: true,     //"転送予定",
  warehousing: true,  //"入庫",
  warehousing_last: true,  //"前年入庫",
  bestBefore: true,   //"賞味期限",
  storeOrder: true,   //"受注",
  shipping_original: true,
  shipping: true,     //"出庫",
  shipping_storeOrder: true,     //"出庫(先付含)",
  shipping_last: true,     //"前年出庫",
  stockNormal: true,  //"在庫（良品）",
  stockNormal1: true,  //"在庫[一般]",
  stockNormal2: true,  //"在庫[特売]",
  stockNormal3: true,  //"在庫[予約]",
  stockUseUp: true,   //"在庫（出切）",
  stockReturned: true,//"在庫（返品）",
  weather: true, //"天気",

  similarItem_warehousing: true,
  similarItem_shipping: true,
  similarItem_stockNormal: true,

}

export const headerColClasses = [
  {
    no : "header_no",
    attribute1Label: "header", attribute1Value: "header", 
    attribute2Label: "header", attribute2Value: "header", 
    itemTag: "header", 
    rowHeader : "header", 
    // T001: "mainpage_T", T002: "mainpage_T", T003: "mainpage_T", T004: "mainpage_T", T005: "mainpage_T", T006: "mainpage_T", T007: "mainpage_T", T008: "mainpage_T", T009: "mainpage_T", T010: "mainpage_T",
    // T011: "mainpage_T", T012: "mainpage_T", T013: "mainpage_T", T014: "mainpage_T", T015: "mainpage_T", T016: "mainpage_T", T017: "mainpage_T", T018: "mainpage_T", T019: "mainpage_T", T020: "mainpage_T",
    // T021: "mainpage_T", T022: "mainpage_T", T023: "mainpage_T", T024: "mainpage_T", T025: "mainpage_T", T026: "mainpage_T", T027: "mainpage_T", T028: "mainpage_T", T029: "mainpage_T", T030: "mainpage_T",
    // T031: "mainpage_T", T032: "mainpage_T", T033: "mainpage_T", T034: "mainpage_T", T035: "mainpage_T", T036: "mainpage_T", T037: "mainpage_T", T038: "mainpage_T", T039: "mainpage_T", T040: "mainpage_T",
    // T041: "mainpage_T", T042: "mainpage_T", T043: "mainpage_T", T044: "mainpage_T", T045: "mainpage_T", T046: "mainpage_T", T047: "mainpage_T", T048: "mainpage_T", T049: "mainpage_T", T050: "mainpage_T",
    // T051: "mainpage_T", T052: "mainpage_T", T053: "mainpage_T", T054: "mainpage_T", T055: "mainpage_T", T056: "mainpage_T", T057: "mainpage_T", T058: "mainpage_T", T059: "mainpage_T", T060: "mainpage_T",

    //Excelデータシート用
    centerCD : "header",
    centerNM : "header",
    itemCD : "header",
    itemNM : "header",
  
    area : "header",
    pref : "header",
       
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",
    tanaban : "header",
    tokuisakiCD : "header",
    tokuisakiNM : "header",
    tokuisakiItemCD : "header",
    teibanTokuisakiCD : "header",
    teibanTokuisakiNM : "header",
    teibanTokuisakiItemCD : "header",
    oyaCenter : "header",
    koCenter : "header",
  },
  {
    no : "header_no",
    attribute1Label: "header", attribute1Value: "header", 
    attribute2Label: "header", attribute2Value: "header", 
    itemTag : "header", 
    rowHeader : "header", 
    // T001: "mainpage_T", T002: "mainpage_T", T003: "mainpage_T", T004: "mainpage_T", T005: "mainpage_T", T006: "mainpage_T", T007: "mainpage_T", T008: "mainpage_T", T009: "mainpage_T", T010: "mainpage_T",
    // T011: "mainpage_T", T012: "mainpage_T", T013: "mainpage_T", T014: "mainpage_T", T015: "mainpage_T", T016: "mainpage_T", T017: "mainpage_T", T018: "mainpage_T", T019: "mainpage_T", T020: "mainpage_T",
    // T021: "mainpage_T", T022: "mainpage_T", T023: "mainpage_T", T024: "mainpage_T", T025: "mainpage_T", T026: "mainpage_T", T027: "mainpage_T", T028: "mainpage_T", T029: "mainpage_T", T030: "mainpage_T",
    // T031: "mainpage_T", T032: "mainpage_T", T033: "mainpage_T", T034: "mainpage_T", T035: "mainpage_T", T036: "mainpage_T", T037: "mainpage_T", T038: "mainpage_T", T039: "mainpage_T", T040: "mainpage_T",
    // T041: "mainpage_T", T042: "mainpage_T", T043: "mainpage_T", T044: "mainpage_T", T045: "mainpage_T", T046: "mainpage_T", T047: "mainpage_T", T048: "mainpage_T", T049: "mainpage_T", T050: "mainpage_T",
    // T051: "mainpage_T", T052: "mainpage_T", T053: "mainpage_T", T054: "mainpage_T", T055: "mainpage_T", T056: "mainpage_T", T057: "mainpage_T", T058: "mainpage_T", T059: "mainpage_T", T060: "mainpage_T",

    //Excelデータシート用
    centerCD : "header",
    centerNM : "header",
    itemCD : "header",
    itemNM : "header",
  
    area : "header",
    pref : "header",
       
    janCD : "header",
    makerCD : "header",
    makerNM : "header",
    categoryCD : "header",
    categoryNM : "header",
    blIrisu : "header",
    csIrisu : "header",
    lot : "header",
    capacity : "header",
    bestBefore : "header",
    teika : "header",
    tatene : "header",
    tanaban : "header",
    tokuisakiCD : "header",
    tokuisakiNM : "header",
    tokuisakiItemCD : "header",
    teibanTokuisakiCD : "header",
    teibanTokuisakiNM : "header",
    teibanTokuisakiItemCD : "header",
    oyaCenter : "header",
    koCenter : "header",
  },
];
[...Array(maxWeeksPastWeekly+(maxWeeksPastWeekly+maxWeeksFuture)*7)].forEach((_, index) => {
  const nnn = `000${index+1}`.slice(-3);
  const key = `T${nnn}`;
  headerColClasses[0][key] = "mainpage_T";
  headerColClasses[1][key] = "mainpage_T";
});


export const headerRowClasses = 
{
  storeSales: "rowHeader_storeSales",   //"店頭売上",
  transfer: "rowHeader_transfer",     //"転送予定",
  warehousing: "rowHeader_warehousing",  //"入庫",
  warehousing_last: "rowHeader_warehousing_last",  //"入庫",
  bestBefore: "rowHeader_bestBefore",   //"賞味期限",
  storeOrder: "rowHeader_storeOrder",   //"受注",
  shipping_original: "rowHeader_shipping_original",
  shipping: "rowHeader_shipping",     //"出庫(先付含)",
  shipping_storeOrder: "rowHeader_shipping_storeOrder",     //"出庫",
  shipping_last: "rowHeader_shipping_last",     //"出庫",
  stockNormal: "rowHeader_stockNormal",  //"在庫（良品）",
  stockNormal1: "rowHeader_stockNormal",  //"在庫[一般]",
  stockNormal2: "rowHeader_stockNormal",  //"在庫[特売]",
  stockNormal3: "rowHeader_stockNormal",  //"在庫[予約]",
  stockUseUp: "rowHeader_stockUseUp",   //"在庫（出切）",
  stockReturned: "rowHeader_stockReturned",//"在庫（返品）",
  weather: "rowHeader_weather",//"天気",

  similarItem_warehousing: "rowHeader_similarItem_warehousing",
  similarItem_shipping: "rowHeader_similarItem_shipping",
  similarItem_stockNormal: "rowHeader_similarItem_stockNormal",
};
export const bodyRowClasses = 
{
  storeSales: "body_storeSales",   //"店頭売上",
  transfer: "body_transfer",     //"転送予定",
  warehousing: "body_warehousing",  //"入庫",
  warehousing_last: "body_warehousing_last",  //"入庫",
  bestBefore: "body_bestBefore",   //"賞味期限",
  storeOrder: "body_storeOrder",   //"受注",
  shipping_original: "body_shipping_original",
  shipping: "body_shipping",     //"出庫",
  shipping_storeOrder: "body_shipping_storeOrder",     //"出庫(先付含)",
  shipping_group1: "body_shipping_group1",
  shipping_last: "body_shipping_last",     //"出庫",
  stockNormal: "body_stockNormal",  //"在庫（良品）",
  stockNormal1: "body_stockNormal1",  //"在庫[一般]",
  stockNormal2: "body_stockNormal2",  //"在庫[特売]",
  stockNormal3: "body_stockNormal3",  //"在庫[予約]",
  stockUseUp: "body_stockUseUp",   //"在庫（出切）",
  stockReturned: "body_stockReturned",//"在庫（返品）",
  weather: "body_weather",//"天気",

  similarItem_warehousing: "body_similarItem_warehousing",
  similarItem_shipping: "body_similarItem_shipping",
  similarItem_stockNormal: "body_similarItem_stockNormal",
};

export const colWidths =
{
  no : 40,
  attribute1Label: 75, 
  attribute1Value: 150, 
  attribute2Label: 75, 
  attribute2Value: 100, 
  itemTag: 130, 
  rowHeader : 100, 
  // T001: 60, T002: 60, T003: 60, T004: 60, T005: 60, T006: 60, T007: 60, T008: 60, T009: 60, T010: 60,
  // T011: 60, T012: 60, T013: 60, T014: 60, T015: 60, T016: 60, T017: 60, T018: 60, T019: 60, T020: 60,
  // T021: 60, T022: 60, T023: 60, T024: 60, T025: 60, T026: 60, T027: 60, T028: 60, T029: 60, T030: 60,
  // T031: 60, T032: 60, T033: 60, T034: 60, T035: 60, T036: 60, T037: 60, T038: 60, T039: 60, T040: 60,
  // T041: 60, T042: 60, T043: 60, T044: 60, T045: 60, T046: 60, T047: 60, T048: 60, T049: 60, T050: 60,
  // T051: 60, T052: 60, T053: 60, T054: 60, T055: 60, T056: 60, T057: 60, T058: 60, T059: 60, T060: 60,

  //Excelデータシート用
  centerCD : 50,
  centerNM : 80,
  itemCD : 95,
  itemNM : 110,

  area : 50,
  pref : 70,
     
  janCD : 120,
  makerCD : 60,
  makerNM : 100,
  categoryCD : 40,
  categoryNM : 110,
  blIrisu : 40,
  csIrisu : 40,
  lot : 30,
  capacity : 50,
  bestBefore : 40,
  teika : 50,
  tatene : 55,
  tanaban : 80,
  tokuisakiCD : 60, 
  tokuisakiNM : 100, 
  tokuisakiItemCD : 100, 
  teibanTokuisakiCD : 60, 
  teibanTokuisakiNM : 100, 
  teibanTokuisakiItemCD : 100, 
  oyaCenter : 100, 
  koCenter : 100, 
};
[...Array(maxWeeksPastWeekly+(maxWeeksPastWeekly+maxWeeksFuture)*7)].forEach((_, index) => {
  const nnn = `000${index+1}`.slice(-3);
  const key = `T${nnn}`;
  colWidths[key] = 60;
});

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  attribute1Label: {type: 'text', readOnly: true, className: 'htRight'}, 
  attribute1Value: {type: 'text', readOnly: true,}, 
  attribute2Label: {type: 'text', readOnly: true, className: 'htRight'}, 
  attribute2Value: {type: 'text', readOnly: true,}, 
  itemTag: {type: 'text', readOnly: true,}, 
  rowHeader : {type: 'text', readOnly: true,}, 

  //Excelデータシート用
  centerCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  centerNM : {type: 'text', readOnly: true,}, 
  itemCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  itemNM : {type: 'text', readOnly: true,}, 

  area : {type: 'text', readOnly: true,}, 
  pref : {type: 'text', readOnly: true,}, 
     
  janCD : {type: 'text', readOnly: true,}, 
  makerCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  makerNM : {type: 'text', readOnly: true,}, 
  categoryCD : {type: 'text', readOnly: true, className: 'htCenter',}, 
  categoryNM : {type: 'text', readOnly: true,}, 
  blIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  csIrisu : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  lot : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},
  capacity : {type: 'text', readOnly: true, className: 'htRight',}, 
  bestBefore : {type: 'text', readOnly: true, className: 'htRight',}, 
  teika : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},}, 
  tatene : {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.00',},},
  tanaban : {type: 'text', readOnly: true,}, 
  tokuisakiCD : {type: 'text', readOnly: true,}, 
  tokuisakiNM : {type: 'text', readOnly: true,}, 
  tokuisakiItemCD : {type: 'text', readOnly: true,}, 
  teibanTokuisakiCD : {type: 'text', readOnly: true,}, 
  teibanTokuisakiNM : {type: 'text', readOnly: true,}, 
  teibanTokuisakiItemCD : {type: 'text', readOnly: true,}, 
  oyaCenter : {type: 'text', readOnly: true,}, 
  koCenter : {type: 'text', readOnly: true,}, 
};

export const rowDataType =
{
  storeSalesPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},   //"店頭売上",
  storeSalesBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},   //"店頭売上",
  storeSalesCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},   //"店頭売上",
  transferPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},     //"転送予定",
  transferBL: {type: 'numeric', readOnly: false, numericFormat: {pattern: '0,0.#',},},     //"転送予定",
  transferCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},     //"転送予定",
  bestBefore: {type: 'text', readOnly: true, },                          //"賞味期限",
  storeOrderPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},   //"受注",
  storeOrderBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},   //"受注",
  storeOrderCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},   //"受注",
  warehousingPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"入庫",
  warehousingBL: {type: 'numeric', readOnly: false, numericFormat: {pattern: '0,0.#',},},  //"入庫",
  warehousingCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"入庫",
  warehousing_lastPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"前年入庫",
  warehousing_lastBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},  //"前年入庫",
  warehousing_lastCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"前年入庫",
  shipping_originalPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},     //"出庫",
  shipping_originalBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},     //"出庫",
  shipping_originalCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},     //"出庫",
  shippingPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},     //"出庫",
  shippingBL: {type: 'numeric', readOnly: false, numericFormat: {pattern: '0,0.#',},},     //"出庫",
  shippingCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},     //"出庫",
  shipping_storeOrderPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},     //"出庫(先付含)",
  shipping_storeOrderBL: {type: 'numeric', readOnly: false, numericFormat: {pattern: '0,0.#',},},     //"出庫(先付含)",
  shipping_storeOrderCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},     //"出庫(先付含)",
  shipping_lastPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},     //"前年出庫",
  shipping_lastBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},     //"前年出庫",
  shipping_lastCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},     //"前年出庫",
  shipping_group1PS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},     //"前年出庫",
  shipping_group1BL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},     //"前年出庫",
  shipping_group1CS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},     //"前年出庫",
  stockNormalPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"在庫（良品）",
  stockNormalBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},  //"在庫（良品）",
  stockNormalCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"在庫（良品）",
  stockNormal1PS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"在庫（良品）",
  stockNormal1BL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},  //"在庫（良品）",
  stockNormal1CS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"在庫（良品）",
  stockNormal2PS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"在庫（良品）",
  stockNormal2BL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},  //"在庫（良品）",
  stockNormal2CS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"在庫（良品）",
  stockNormal3PS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"在庫（良品）",
  stockNormal3BL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},  //"在庫（良品）",
  stockNormal3CS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"在庫（良品）",

  stockUseUpPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},   //"在庫（出切）",
  stockUseUpBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},   //"在庫（出切）",
  stockUseUpCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},   //"在庫（出切）",
  stockReturnedPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},//"在庫（返品）",
  stockReturnedBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},//"在庫（返品）",
  stockReturnedCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},//"在庫（返品）",

  weather: { type: 'text', readOnly: true, },                          //"天気",

  similarItem_warehousingPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"入庫",
  similarItem_warehousingBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},  //"入庫",
  similarItem_warehousingCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"入庫",
  similarItem_shippingPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},     //"出庫",
  similarItem_shippingBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},     //"出庫",
  similarItem_shippingCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},     //"出庫",
  similarItem_stockNormalPS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0',},},  //"在庫（良品）",
  similarItem_stockNormalBL: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.#',},},  //"在庫（良品）",
  similarItem_stockNormalCS: {type: 'numeric', readOnly: true, numericFormat: {pattern: '0,0.##',},},  //"在庫（良品）",

};

//合計行に表示する項目
export const rowDataTotalVisible =
{
  storeSales: true,   //"店頭売上",
  transfer: true,     //"転送予定",
  warehousing: true,  //"入庫",
  warehousing_last: true,  //"前年入庫",
  bestBefore: false,  //"賞味期限",
  storeOrder: true,   //"受注",
  shipping_original: false,     //"出庫",
  shipping: true,     //"出庫(先付含)",
  shipping_storeOrder: true,     //"出庫",
  shipping_last: true,     //"前年出庫",
  stockNormal: true,  //"在庫（良品）",
  stockNormal1: false,  //"在庫（良品）",
  stockNormal2: false,  //"在庫（良品）",
  stockNormal3: false,  //"在庫（良品）",
  stockUseUp: true,   //"在庫（出切）",
  stockReturned: true,//"在庫（返品）",
  weather: true, //"天気",

  similarItem_warehousing: false,
  similarItem_shipping: false,
  similarItem_stockNormal: false,
};
//類似商品指定なしの行に表示する項目
export const rowDataNoSimilarVisible =
{
  storeSales: true,   //"店頭売上",
  transfer: true,     //"転送予定",
  warehousing: true,  //"入庫",
  warehousing_last: true,  //"前年入庫",
  bestBefore: true,  //"賞味期限",
  storeOrder: true,   //"受注",
  shipping_original: true,     //"出庫",
  shipping: true,     //"出庫",
  shipping_storeOrder: true,     //"出庫(先付含)",
  shipping_last: true,     //"前年出庫",
  stockNormal: true,  //"在庫（良品）",
  stockNormal1: true,  //"在庫（良品）",
  stockNormal2: true,  //"在庫（良品）",
  stockNormal3: true,  //"在庫（良品）",
  stockUseUp: true,   //"在庫（出切）",
  stockReturned: true,//"在庫（返品）",
  weather: true, //"天気",

  similarItem_warehousing: false,
  similarItem_shipping: false,
  similarItem_stockNormal: false,
};

//列定義情報（動的パラメータ）
interface MainPageColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各行のキー
  rowKeys: string[],
}

export class MainPageColRowModel implements MainPageColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: MainPageColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//MainPage用の列モデル
export const mainPageColRowModelDefault:MainPageColRowModel = new MainPageColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...termColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: rowKeys,
});


//MainPage用の列モデル
export const mainPageColRowModel_ExcelDataSheet:MainPageColRowModel = new MainPageColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys_ExcelDataSheet.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys_ExcelDataSheet,
    ...headerSubColKeys_ExcelDataSheet,
    ...rowHeaderColKeys,
    ...termColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
  rowKeys: rowKeys,
});
