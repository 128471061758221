
//CodeName
interface CodeNameInterface {
  code: string,
  name: string,
  group_name?: string;
  group_code?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
  option6?: string;
  katakana?: string;
  ryaku?: string;
}

export class CodeName implements CodeNameInterface {
  code: string;
  name: string;
  group_name?: string;
  group_code?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
  option6?: string;
  katakana?: string;
  ryaku?: string;

  constructor(param: CodeNameInterface) {
    this.code = param.code;
    this.name = param.name;
    this.group_code = param.group_code;
    this.group_name = param.group_name;
    this.option1 = param.option1;
    this.option2 = param.option2;
    this.option3 = param.option3;
    this.option4 = param.option4;
    this.option5 = param.option5;
    this.option6 = param.option6;
    this.katakana = param.katakana;
    this.ryaku = param.ryaku;
  }
}

export const getCodeNameLabel = (option: CodeName|string, hideCode:boolean) => {
  return option ? (typeof option == 'string' ? option : (!hideCode ? `${option.code} ${option.name}` : option.name ? option.name : option.code)) : '';
}
